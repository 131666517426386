import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import '../src/assets/scss/styles.scss'
import '../src/assets/fonts/Nunito/Nunito-Regular.ttf'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import 'guru-react-icons/dist/index.css'
import { Provider } from 'unstated-typescript'
import { IonApp } from '@ionic/react'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import 'moment/locale/es'

// Configuramoso Redux Toolkit
import MainApp from './MainApp'
import AppProvider from './context/AppProvider'

declare global {
  interface Window {
    firebase: any
  }
}

moment.locale('es')

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
  release: process.env.REACT_APP_BUILD_NUMBER,
})

class App extends React.PureComponent<any, any> {
  render() {
    return (
      <IonApp placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Provider>
          <AppProvider>
            <MainApp />
          </AppProvider>
        </Provider>
      </IonApp>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()
