import React from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  IonHeader,
  IonIcon,
  IonPage,
  IonButton,
  IonContent,
} from '@ionic/react'
import { getLocalStorageObject, getLocalUserData } from '../../utils/localstorageData'
import { first } from 'lodash'
import { AppLauncher } from '@capacitor/app-launcher';

//Modelo
import User from '../../models/User'
import Settings from '../../models/Settings'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import Office from '../../models/Office'
import  Notifications   from '../../models/Notifications'
import TextVariations from '../../models/TextVariations'

//Componente
import ToolBar from '../../components/tool-bar/ToolBar'
import PhotosUser from './../photosUser/PhotosUser'
import LoadingCover from '../../components/loading-cover/LoadingCover'
import GlobalComponents from '../../components'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import TextInput from '../../components/input-form/TextInput'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import AlertModal from '../../components/modals/AlertModal'
import OfficeUser from '../../pages/office/Office'

//Iconos
import cartToolbarIcon from './../../assets/icons/icon_carrito.svg'
import helpIcon from './../../assets/icons/icn_help.svg'
import termsConditionsIcon from './../../assets/icons/icn_terms.svg'
import logoutIcon from './../../assets/icons/act_logout.svg'
import bell from '../../assets/icons/nav_notification.svg'
import userConditions from './../../assets/icons/icn_usercond.svg'
import garbage from '../../assets/icons/act_delete.svg'
import privacyIcon from '../../assets/icons/icn_privacy.svg'
import plusIcon from '../../assets/icons/act_plus.svg'
import Arrow from './../../assets/icons/nav_right.svg'
import Plus from './../../assets/icons/act_plus.svg'
import backSmallArrow from '../../assets/icons/nav_left.svg'

//Endpoints
import { typeOfBussiness, sendOptionalRegistration } from '../../clients/register'
import { getCartId, getProductCart } from '../../clients/cartNew'
import { getOfficesByUserId, getPendingOfficesRequestByUserId } from '../../clients/offices'

// Eventos
import { sendMetric, sendMetricWithSource } from '../firebase/firebaseTags'
// Notificaciones
import { refreshNotifications } from '../../pages/reusableFunctions/Notifications'

// Utils
import { countryMx, getCountryCode, isPhoneValidByCountry, phoneLengthByCountry, phonePlaceholderByCountry } from '../../utils/countriesTexts'
import { validateEmail } from '../../utils/basetypes'
import { currentApp } from '../../utils/currentCountry'

// Styles
import './../../assets/scss/general.scss'
import './Profile.scss'
import { navigateToCart } from '../../utils/navigation'
import { formatAddress, limitText } from '../../utils/strings'


type IPathParams = {}

type RouteState = {
  fromCart?: 'simple' | { singleProviderId?: string }
  deeplinkGuruPro?: any
  onboarding?: any
  nextPagePath?: any
  onclickNextPagePath?: any
  origin?: number
  fromInside?: boolean
}

type Props = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  user: User
  settings: Settings
  productModel: ProductModel
  cartModel: CartModel
  offices: Office
  notification: Notifications
}
interface State {
  user: any
  initialFormData: any
  form: any
  saving: any
  office: any
  offices: any
  pendingOffices: any
  userInfo: any
  typeOfBussiness: any
  editTypeBussiness: any
  businessType: any
  otherTypeOfBusiness: string
  spinner: boolean
  otherUser: string
  btnDisabled: boolean
  valueForm: string
  valueName: string
  edit: boolean
  passworToupdate: boolean
  newPasswordValue: any
  passwordConfirmationValue: any
  colorBordeNewPassword: string
  colorBordeConfirmation: string
  editPhotos: boolean
  editBallot: boolean
  editPatent: boolean
  appVersion: any
  nextUpdate: any
  countryData: any
  unreadNotificationsUser: number
  loadingCover: boolean
  showOnboarding: boolean
  onboarding: any
  countryCode: string
  cart: any
  modifiedPassword: boolean
  isOpenAddOffice: boolean
  showViewOffice: any
  officeSelected: any
  branchOffice: boolean
  deleteAccountModal: any
  showPassword: boolean
  showConfirmPassword: boolean
}

class Profile extends React.PureComponent<Props, State> {
  state: State = {
    user: null,
    form: {},
    initialFormData: {},
    saving: false,
    office: {},
    offices: [],
    pendingOffices: [],
    userInfo: '',
    typeOfBussiness: '',
    editTypeBussiness: '',
    businessType: '',
    otherTypeOfBusiness: '',
    spinner: false,
    otherUser: '',
    btnDisabled: false,
    valueForm: '',
    valueName: '',
    edit: false,
    passworToupdate: false,
    newPasswordValue: '',
    passwordConfirmationValue: '',
    colorBordeNewPassword: '#ccc',
    colorBordeConfirmation: '#ccc',
    editPhotos: false,
    editBallot: false,
    editPatent: false,
    appVersion: { update: false, url: null, required: false },
    nextUpdate: null,
    countryData: '',
    unreadNotificationsUser: 0,
    loadingCover: true,
    showOnboarding: false,
    onboarding: [],
    countryCode: '',
    cart: 0,
    modifiedPassword: false,
    isOpenAddOffice: false,
    showViewOffice:{  isOpenViewOffice: false , action:""},
    officeSelected: {},
    branchOffice: false,
    deleteAccountModal: null,
    showPassword: false,
    showConfirmPassword: false
  } 

  textVariations = new TextVariations('profile')

  componentDidMount = async () => {
    localStorage.setItem('onboarding', JSON.stringify(false))
    this.loadProfile()
  }
  
  loadProfile = async (changedOffice?: any) => {
    try {
      const { user } = this.props
      const getUser: any = (await user.getUser()) || {}
      const countryData = localStorage.getItem('countryName')
      const country = countryData ? JSON.parse(countryData) :  null 
      const userInfo: any = (await user.getUserMe()) || null
      const id = userInfo && userInfo.bussinesType
  
      const result: any = id ? await user.userProfileOptional(id) : ''
      const editTypeBussiness = await typeOfBussiness()
 
      const onboarding = await user.onboarding()
      
      this.setState({
        user: getUser,
        initialFormData: this.initFormInfo(userInfo),
        saving: false,
        userInfo: userInfo,
        typeOfBussiness: result,
        editTypeBussiness: editTypeBussiness,
        edit: this.props.location && this.props.location.state && this.props.location.state.fromCart ? true : false,
        countryData: {
          countryName: country.countryName,
          countryCode: country.countryCode
        },
        loadingCover: false,
        onboarding,
        countryCode: country.countryCode,
      })

      const idCart = await getCartId()
      let productCart = await getProductCart(idCart.data._id)
      const cart = productCart?.cart?.products.filter((item:any) => !item.paid)
      this.setState({cart: cart.length})
      
      if (userInfo._id) {
        const stateNotifications = await refreshNotifications(userInfo._id)
        this.setState({ unreadNotificationsUser: stateNotifications})
      }

      const offices: any = await this.getOffices()
      const pendingOffices: any = await this.getPendingOffices()
      const currentOffice = localStorage.getItem('currentOffice')
      const office =  currentOffice ? JSON.parse(currentOffice) : first(offices)
      this.props.offices.setOffice(office)
      this.setState({
        offices,
        pendingOffices,
        office: changedOffice ? changedOffice : office
      })

    } catch (error) {
      console.error(error);
    }
  }
  
  getOffices = async () => {
    const userData = localStorage.getItem('@user')
    let parsedUser:any = ''
    if (userData) parsedUser = JSON.parse(userData)

    return await getOfficesByUserId(parsedUser.id)
  }
  
  getPendingOffices = async () => {
    const userData = localStorage.getItem('@user')
    let parsedUser:any = ''
    if (userData) parsedUser = JSON.parse(userData)

    const pendingOfficesRequest = await getPendingOfficesRequestByUserId(parsedUser.id)
    const pendingOfficesCreateRequest = pendingOfficesRequest ? pendingOfficesRequest.filter((p: any) => {
      return p.action === 'create'
    }) : []
    return pendingOfficesCreateRequest
  }

  initFormInfo = (userData: any) => {
    if (!userData) return {}

    let alternativePhones = userData?.alternativePhones || []
    let additionalEmails = userData?.additionalEmails?.length ? userData.additionalEmails : []

    alternativePhones = alternativePhones.filter((phone: any) => Boolean(phone))
    additionalEmails = additionalEmails.filter((email: any) => Boolean(email))

    const phone = userData.phone ? userData.phone.trim() : ""
    const email = userData?.email ? userData?.email.trim() : ""

    const formInfo = {
      phone,
      email,
      alternativePhones,
      additionalEmails,
      otherTypeOfBusiness: userData.bussinesTypeOther,
    }

    return formInfo
  }
  
  goToCart = () => {
    sendMetricWithSource('view_cart', 'profile')
    navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
  }

  goToNotifications = () => {
    sendMetric('view_notifications')
    this.props.history.push('/notifications')
  }

  onChangeInput = (field: string, value: any) => {
    const { form } = this.state

    const addedFieldObject: any = {}
    addedFieldObject[field] = value

    this.setState({
      form: Object.assign(form, addedFieldObject),
      valueForm: value,
      valueName: field,
    })
  }

  prefixNumber = () => {
    const countryName = localStorage.getItem('countryName') 
	  const country = countryName ? JSON.parse(countryName) :  ''
  
    return currentApp().find(route => country.countryCode === route.country) 
  }

  onChangeAlternativePhones = (action: string, index?: number, inputVal?: string) => {
    const { form } = this.state
    const { alternativePhones } = form
    switch (action) {
      case "change":
        if (index !== undefined) alternativePhones[index] = inputVal
        break;
      case "add":
        alternativePhones.push('')
        break;
      case "delete":
        alternativePhones.splice(index, 1)
        break;
      default:
        break;
    }

    this.assignAlternativePhones(alternativePhones, form)
  }

  onChangeAdditionalEmails = (action: string, index?: number, inputVal?: string) => {
    const { form } = this.state
    const { additionalEmails } = form

    switch (action) {
      case "change":
        if (index !== undefined) additionalEmails[index] = inputVal
        break;
      case "add":
        additionalEmails.push('')
        break;
      case "delete":
        additionalEmails.splice(index, 1)
        break;
    
      default:
        break;
    }

    this.assignAdditionalEmails(additionalEmails, form)
  }

  assignAlternativePhones = (alternativePhones: any, form: any) => {
    const addedFieldObject: any =  {}
    addedFieldObject['alternativePhones'] = alternativePhones

    this.setState({ form: Object.assign(form, addedFieldObject) })
    this.forceUpdate()
  }
  
  assignAdditionalEmails = (additionalEmails: any, form: any) => {
    const addedFieldObject: any =  {}
    addedFieldObject['additionalEmails'] = additionalEmails

    this.setState({ form: Object.assign(form, addedFieldObject) })
    this.forceUpdate()
  }

  toggleEdit = () => {
    const { edit, initialFormData } = this.state
    const initialData = { ...initialFormData }
    this.setState({
      edit: !edit,
      saving: false,
      form: initialData,
    })
  }

  saveUser = () => {
    this.setState(
      {
        saving: true,
        spinner: true,
        edit: false,
      },

      async () => {
        try {
          const { initialFormData, userInfo, form } = this.state
          const userSave = { ...form }

          userSave.alternativePhones = userSave.alternativePhones.filter((phone: any) => Boolean(phone))
          this.updateLocalUser(userSave)
          await this.props.user.saveUser(userSave)
          
          this.setState({
            user: Object.assign({}, this.state.user, this.state.form),
            spinner: false,
            initialFormData: Object.assign(initialFormData, {
              email: userSave.email,
              additionalEmails: userSave.additionalEmails,
              phone: userSave.phone,
              alternativePhones: userSave.alternativePhones,
            }),
            userInfo: Object.assign(userInfo, {
              email: userSave.email,
            })
          }, () => {
            if (this.props.location.state) {
              const fromCart = this.props.location.state.fromCart
              if (fromCart != null) navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
            }
          },
        )} catch (err) {
          this.setState({
            saving: false,
          })
        }
      },
    )
  }

  updateLocalUser = (newUser: any) => {
    const { phone, email } = newUser
    const previousUser = getLocalStorageObject('@user')
    const updatedUser = { ...previousUser, phone, email }
    localStorage.setItem('@user', JSON.stringify(updatedUser))
  }

  onOpenChangeOffice = async (event?: any | undefined) => {
    const { editTypeBussiness } = this.state
    const value = event && event.target && event.target.value

    this.setState({
      businessType: value,
    })

    let send = {}

    if (value) {
      send = { ...send, bussinesType: value }
      await sendOptionalRegistration(send)
      const userInfo: any = (await this.props.user.getUserMe()) || null
      const id = userInfo && userInfo.bussinesType
      const result: any = await this.props.user.userProfileOptional(id)

      this.setState({
        typeOfBussiness: result,
        userInfo: userInfo,
      })
    }

    if (editTypeBussiness) {
      editTypeBussiness.map((r: any) => {
        return (
          r._id === value &&
          r.name === 'Otro' &&
          this.setState({
            btnDisabled: true,
          })
        )
      })
    }
  }

  other = async () => {
    const { form } = this.state

    let send = {}

    if (form.otherTypeOfBusiness) {
      send = { ...send, bussinesTypeOther: form && form.otherTypeOfBusiness }
      await sendOptionalRegistration(send)
      const userInfo: any = (await this.props.user.getUserMe()) || null
      const editTypeBussiness = await typeOfBussiness()
      this.setState({
        otherUser: userInfo.bussinesTypeOther,
        editTypeBussiness: editTypeBussiness,
        btnDisabled: false,
      })
    }
  }

  signOut = async () => {
    const { cartModel, user } = this.props
    await cartModel.clearCart()
    await user.signOut()
    this.props.history.push('/')
    window.location.reload()
  }

  passworToupdate = () => {
    const { passworToupdate } = this.state
    this.setState({
      passworToupdate: !passworToupdate,
    })
  }

  onChangeNewPassword = (event: any) => {
    this.setState({
      newPasswordValue: event,
      colorBordeNewPassword: event.length < 6 ? '#EB2F06' : '#ccc',
      modifiedPassword: true,
    })
  }

  onChangePasswordConfirmation = (event: any) => {
    this.setState({
      passwordConfirmationValue: event,
      colorBordeConfirmation: event.length < 6 ? '#EB2F06' : '#ccc',
      modifiedPassword: true,
    })
  }

  savePassword = async () => {
    const { newPasswordValue, passwordConfirmationValue } = this.state

    this.setState({
      spinner: true,
      modifiedPassword: false,
    })
    if (newPasswordValue === passwordConfirmationValue) {
      const data = {
        password: passwordConfirmationValue,
      }
      const result = await sendOptionalRegistration(data)
      if (result) {
        this.setState({
          spinner: false,
        })
      }
      this.passworToupdate()
    }
  }


  toggleEditPhotos = (type: string) => {
    const { editPhotos, editBallot, editPatent } = this.state

    switch (type) {
      case 'photosRut':
        this.setState({
          editPhotos: !editPhotos,
        })
        break
      case 'photosBallot':
        this.setState({
          editBallot: !editBallot,
        })
        break
      case 'patent':
        this.setState({
          editPatent: !editPatent,
        })
        break
    }
  }

  photoUpdated = (data: any) => {
    this.setState({
      userInfo: data.user,
    })
    sendMetric("upload_content")
  }
 
  goToTerms = (termsIndex: number) => {
    sendMetric("click_terms")
    this.props.history.push('/terms-conditions', { origin: termsIndex, fromInside: true})
  }

  goToHelp = () => {
    sendMetric("view_help")
    this.props.history.push('/faq')
  }

  goStore = async () => {
    const { appVersion } = this.state
    if (appVersion && appVersion.update && appVersion.url) {
      AppLauncher.openUrl({ url: appVersion.url })
    }
  }

  goToCategory = () => {
    sendMetricWithSource('view_categories', 'profile')
    this.props.history.push('/categories')
  }
  
  loadByComuneId = async (changedOffice: any) => {
    this.setState({user: null})
    await this.loadProfile(changedOffice)
  }

  validateContactForm = () => {
    try {
      const { form } = this.state
      const { phone, alternativePhones, email, additionalEmails } = form
      const isPhoneValid = this.validateContactPhones(phone, alternativePhones)
      const isEmailValid = validateEmail(email)
      const areAdditionalEmailsValid = this.validateAdditionalEmails(additionalEmails)

      return !(isPhoneValid && isEmailValid && areAdditionalEmailsValid)
      
    } catch(err) {
      console.error(err)
      return false
    }
    
  }

  phonePlaceholder = (countryCode = 'CL') => {
    const placeholder = new Map([
      ['CL', '9 1234 5678'],
      ['PE', '912 345 678']
    ])
    return placeholder.get(countryCode)
  }

  renderContactInfoCondition = (editOpen: any, userInfo: any, form: any, initialFormData: any, countryCode: string) => {
    if (editOpen) {
      return (
        <>
          <div className="edit-email-phone">

            <div className="dynamic-input-container">

              <div className="dynamic-input-wrapper">
                <TextInput
                  onChange={(event:any) => this.onChangeInput('phone', event.target.value || '')} 
                  placeholder={phonePlaceholderByCountry()}
                  label="Teléfono"
                  error={"Escribe tu número con el código de área"}
                  value={form.phone}
                  type="tel"
                  isValid={isPhoneValidByCountry(form.phone)}
                  toValidate={form.phone}
                  maxLength={phoneLengthByCountry()}
                  autoComplete="off"
                  iconDefault="icn_phone" 
                  iconValid="icn_phone-blue"
                  iconInvalid="icn_phone-red"
                />
              </div>

              <div onClick={() => this.onChangeAlternativePhones("add")} className="dynamic-input-icon-wrapper" >
                <IonIcon className="dynamic-input-icon" src={plusIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>

            </div>

            {this.renderAlternativePhonesEdit(form.alternativePhones)}

            <div className="dynamic-input-container">

              <div className="dynamic-input-wrapper">
                <TextInput 
                  iconDefault="icn_email" 
                  iconValid="icn_email-blue"
                  iconInvalid="icn_email-red"
                  onChange={(e: any)=> this.onChangeInput('email', e.target.value || '')} 
                  placeholder="Ej: maria@email.com"
                  label="Email"  
                  isValid={!!validateEmail(form.email)}
                  error={"Tu email debe ser válido para recibir mensajes"}
                  value={form.email}
                  type="email"
                  toValidate={form.email}
                  autoComplete="off"
                />
              </div>

              <div onClick={() => this.onChangeAdditionalEmails("add")} className="dynamic-input-icon-wrapper" >
                <IonIcon className="dynamic-input-icon" src={plusIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>

            </div>

            {this.renderAdditionalEmailsEdit(form.additionalEmails)}

          </div>

          <div className="info-update-btn-container">
            <ButtonComponent 
              text={'Guardar cambios'}
              className="btn-tertiary w-100-btn"
              disabled={this.validateContactForm()}
              onClick={this.saveUser}
            />
          </div>
        </>
      )
    } 

    return (
      <div className="edit-email-phone">

        <div>
          <div className="user-field">
            <h5 className="field no-margin">Teléfono</h5>
            <p className="paragraph-2 no-margin profile-info-text">
               {this.prefixNumber()?.prefixNumber} {initialFormData.phone}
            </p>
          </div>
        </div>
        
        {this.renderAltPhonesCondition(initialFormData, userInfo)}

        <div className="user-field">
          <h5 className="field no-margin">Correo</h5>
            <p className="paragraph-2 no-margin profile-info-text">
              {userInfo && userInfo.email}
            </p>
        </div>
        
        {this.renderAdditionalEmailsCondition(initialFormData, userInfo)}

        <div className="info-update-btn-container">
          <ButtonComponent 
            text={'Actualizar datos'}
            className="btn-tertiary w-100-btn"
            onClick={this.toggleEdit }
          />
        </div>
      </div>
    )
  }

  renderAltPhonesCondition = (initialFormData: any, userInfo: any) => {
    if(initialFormData?.alternativePhones?.length) {
      return (
        initialFormData.alternativePhones.map((alternativePhone: any, index: number) => (
          alternativePhone
            ? <div className="user-field">
                <h5 className="field no-margin">Teléfono {index + 2}</h5>
                <p className="paragraph-2 no-margin profile-info-text">
                  {this.prefixNumber()?.prefixNumber} {alternativePhone}
                </p>
              </div>
            : <></>
        ))
      )
    }
    return <></>
  }

  renderAdditionalEmailsCondition = (initialFormData: any, userInfo: any) => {
    if(initialFormData && initialFormData.additionalEmails?.length){
      return (
        initialFormData.additionalEmails.map((email:string, index:number) => (
          <div className="user-field" key={index}>
            <h5 className="field no-margin">Correo {index + 2}</h5>
              <p className="paragraph-2 no-margin profile-info-text">
                {email}
              </p>
          </div>  
        ))
      )
    }
    return <></>
  }

  renderAlternativePhonesEdit = (alternativePhones: any) => {
    const filteredAltPhones = alternativePhones.filter((altPhone: any) => altPhone !== undefined && altPhone !== null)
    return filteredAltPhones.map((alternativePhone: any, index: number) => (
      <div>
        <div className="dynamic-input-container">

          <div className="dynamic-input-wrapper">
            <TextInput
              onChange={((e: any) => this.onChangeAlternativePhones("change", index, e.target.value || ''))}
              placeholder={phonePlaceholderByCountry()}
              label={`Teléfono ${index + 2}`}
              error={"Escribe tu número con el código de área"}
              value={alternativePhone}
              type="tel"
              isValid={isPhoneValidByCountry(alternativePhone)}
              toValidate={alternativePhone}
              maxLength={phoneLengthByCountry()}
              autoComplete="off"
              iconDefault="icn_phone" 
              iconValid="icn_phone-blue"
              iconInvalid="icn_phone-red"
            />
          </div>

          <div onClick={() => this.onChangeAlternativePhones("delete", index)} className="dynamic-input-icon-wrapper">
            <IonIcon className="dynamic-input-icon" src={garbage} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>

        </div>
      </div>
    ))
  }

  renderAdditionalEmailsEdit = (additionalEmails: any) => {
    return additionalEmails.map((additionalEmail: any, index: number) => (
      <div className="dynamic-input-container">

        <div className="dynamic-input-wrapper">
        <TextInput
          onChange={((e: any) => this.onChangeAdditionalEmails("change", index, e.target.value || ''))}
          placeholder="Ingresar correo electrónico"
          label={`Email ${index + 2}`}
          error={"El correo electrónico no es válido"}
          value={additionalEmail}
          type="email"
          isValid={validateEmail(additionalEmail)}
          toValidate={additionalEmail}
          maxLength={100}
          autoComplete="off"
          iconDefault="icn_email" 
          iconValid="icn_email-blue"
          iconInvalid="icn_email-red"
        />
        </div>

        <div onClick={() => this.onChangeAdditionalEmails("delete", index)} className="dynamic-input-icon-wrapper" >
          <IonIcon className="dynamic-input-icon" src={garbage} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </div>

      </div>
    ))
  }

  formatUserRut = (user: any) => {
    if(!user) return null
    try {
      const countryName = localStorage.getItem('countryName') 
      const country = countryName ? JSON.parse(countryName) :  ''
      const countryCode = country?.countryCode
      const userRut = user?.username
  
      const countryFormats: any = [
        { code: 'CL', rut: `${userRut.substr(0, userRut.length - 1)}-${userRut[userRut.length - 1]}` },
        { code: 'PE', rut: userRut },
        { code: 'MX', rut: userRut },
      ]
  
      return countryFormats.find(({code}: any) => code ===  countryCode)?.rut || countryFormats[0].rut

    } catch (err) {
      console.error(err)
      return user?.username
    }
  }

  renderProfileOptions = (countryCode: string) => {
    
    let allOptions = [
      "logout",
      "help",
      "terms",
      "privacy",
      "data",
    ]

    if (countryCode !== "PE") allOptions = allOptions.filter((option: string) => option !== "data")

    const isEven = !Boolean(allOptions.length % 2)
    const renderGroups = []
    let loopIndex = 0

    while (loopIndex < allOptions.length) {
      const indexSum = loopIndex + (isEven ? 4 : 3)
      const slicedArray = allOptions.slice(loopIndex, indexSum)
      renderGroups.push(slicedArray)
      loopIndex = indexSum
    }

    return (
      <div className="container-btn-profile">
        {renderGroups.map((optionsArray: any, index: number) => (
          <div key={index}>
            {optionsArray.map((optionString: string, index: number) => this.profileOptionsSwitch(optionString, index))}
          </div>
        ))}
      </div>
    )
  }

  profileOptionsSwitch = (optionString: string, index: number) => {
    switch (optionString) {
      case "logout":
        return (
          <div className="btn-profile" onClick={this.signOut} key={index}>
            <div className="wrapp-icon">
              <div className="img-menu-profile">
                <IonIcon src={logoutIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
            <p className="paragraph-3 no-margin">Cerrar sesión</p>
          </div>
        )
      case "terms":
        return (
          <div className="btn-profile" onClick={() => this.goToTerms(0)} key={index}>
            <div className="wrapp-icon">
              <div className="img-menu-profile">
                <IonIcon src={termsConditionsIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
            <p className="paragraph-3 no-margin">Términos y condiciones</p>
          </div>
        )
      case "privacy":
        return (
          <div className="btn-profile" onClick={() => this.goToTerms(1)} key={index}>
            <div className="wrapp-icon">
              <div className="img-menu-profile">
                <IonIcon src={privacyIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
            <p className="paragraph-3 no-margin">Políticas de Privacidad</p>
          </div>
        )
      case "data":
        return (
          <div className="btn-profile" onClick={() => this.goToTerms(2)} key={index}>
            <div className="wrapp-icon">
              <div className="img-menu-profile">
                <IonIcon src={userConditions} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
            <p className="paragraph-3 no-margin">Uso de Datos</p>
          </div>
        )
      case "help":
        return (
          <div className="btn-profile" onClick={this.goToHelp} key={index}>
            <div className="wrapp-icon">
              <div className="img-menu-profile">
                <IonIcon src={helpIcon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </div>
            </div>
            <p className="paragraph-3 no-margin">Necesito Ayuda</p>
          </div>
        )
      default:
        return ""
    }
  }

  onClickOffice = (office: any) => {
    this.setState({ officeSelected: office, branchOffice:false })
    this.onEditOrAddOffice("editOffice", true)
  }

  renderOfficesInfo = (offices: any) => {
    const { branchOffice, countryCode, pendingOffices } = this.state
    return (
      <>
        {!branchOffice &&  <h5 className="profile-subtitle">{offices.length > 1 ? "Sucursales" : "Sucursal"}</h5>}
        {offices.map((office: any, index: any) => {
          const { businessName, name, _id } = office;
          if (!office?.location || (!businessName && !name)) return "";
          const { address, city, province, deparment } = office?.location;
          const formattedLocation = formatAddress(address, city, province, deparment)
          
          return (
            <div 
              className="profile-info-card location-container"
              onClick={() => countryCode !== countryMx && this.onClickOffice(office)} 
              key={index}
            >
              <div>
                <h6 className="no-margin" key={_id}>{limitText(formattedLocation, 30, '...')}</h6>
              </div>
              {countryCode !== countryMx && <div className="icon-arrow"><IonIcon src={Arrow} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></div>}
            </div>
          )
        }
        )}
        {pendingOffices.map((p: any) => {
          const formattedPendingLocation = `${p.address} ${p.comune ? `, ${p.comune}` : ''}`
          return (
            <div key={p._id} className='profile-info-card location-container'>
              <div>
                <h6 className="no-margin">
                  {formattedPendingLocation}
                </h6>
              </div>
            </div>
          )
        })}
      </>)
  }

  validateAdditionalEmails = (emails: string[]) => emails.every((email: string) => email !== '' && validateEmail(email))

  validateAlternativePhones = (phones: string[]) => phones.every((phone: string) => phone !== '' &&  isPhoneValidByCountry(phone))

  validateContactPhones = (phone: any, alternativePhones: any) => {
    const isPhoneValid =  isPhoneValidByCountry(phone)
    const areAltPhonesValid = alternativePhones.every((alternativePhone: any) => isPhoneValidByCountry(alternativePhone))

    return isPhoneValid && areAltPhonesValid
  }

  partialValue = (value:string) => <>{value.slice(0, 11)}...</>
  
  showPartialValue = (value:string) => {
    if(value.length < 12) return value
    return this.partialValue(value)
  }

  onEditOrAddOffice = (action:string, value:boolean) => {
    this.setState({
      showViewOffice:{
        isOpenViewOffice: value,
        action:action
      }
    })
  }

  toAcceptAddOffice = () => {
    this.setState({ officeSelected: '', branchOffice:false})
    this.addOffice(false)
    this.onEditOrAddOffice("addOffice", true)
  }

  renderBtnAddOffice = () => {
    return (
      <div className="profile-info-card location-container" onClick={()=>this.addOffice(true)}>
        <div><h6 className="no-margin" >Agregar sucursal</h6></div>
        <div className="icon-arrow"><IonIcon src={Plus} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></div>
      </div>
    )
  }

  closeViewOfficeUser = async () => {
    const { offices } = this.state
   
    if(offices && offices.length >= 5){
      this.onEditOrAddOffice("", false)
      this.setState({branchOffice: true})
    } else {
      this.onEditOrAddOffice("", false)
    }
    const officesCurrent: any = await this.getOffices()
    const pendingOffices: any = await this.getPendingOffices()
    
    this.setState({offices: officesCurrent, pendingOffices})
  }

  arrowBack = () => {
    this.setState({  
      showViewOffice:{
      isOpenViewOffice: false,
      action:""},
      branchOffice:false
  })
  }
  
  onShowOrRemoveOnboar = (ifShowORemoveOnboar:boolean) => this.setState({ showOnboarding: ifShowORemoveOnboar})
  
  variationsValidateIfExist = (value:any) => ( value && value.variations && value.variations.length > 0) 

  goToDeliveries = () => this.props.history.push('/deliveries')
  
  goToOrders = () => this.props.history.push('/orders')

  validatePassword = (password: any) => Boolean(password?.length >= 6)

  addOffice = (value:boolean) => this.setState({isOpenAddOffice: value})

  toCloseModal = () => this.addOffice(false)

  branchOffice  = (value:boolean) => this.setState({branchOffice: value})

  renderUserInfo = () => {
    const { user } = this.state
    const rutUser = this.formatUserRut(user)
    const profileName = getCountryCode() === countryMx ? 'perfil' : 'cuenta';
    const usernameText = this.textVariations.getVariationValue('rut.title') || 'Usuario'

    return (
      <>
        <h1 className="user-name-profile">Tu {profileName}, {this.showPartialValue(user?.name)}</h1>
        <h5 className="profile-subtitle">{usernameText}</h5>
        <p className="paragraph-2 no-margin profile-info-text">{rutUser}</p>
        {user?.rfc
          ? <>
              <h5 className="profile-subtitle">{}</h5>
              <p className="paragraph-2 no-margin profile-info-text">{user?.rfc}</p>
            </>
          : ""
        }
      </>
    )
  }


  renderDeleteAccount = () => {
    const { deleteAccountModal } = this.state;

    const openModalData = {
      label: 'Solicitud de eliminar cuenta',
      text: '¿Estás seguro que deseas enviar una solicitud para eliminar tu cuenta?',
      onDismiss: () => { this.setState({ deleteAccountModal: null }) },
      isOpen: true,
      buttonText: 'Entiendo y acepto',
      buttonAction: this.onAccountDeletionConfirm,
      secondaryButtonText: "Cancelar",
      secondaryButtonAction: () => { this.setState({ deleteAccountModal: null }) },
      hasError: true,
    }
    
    return (
      <>
        {deleteAccountModal ? <AlertModal {...deleteAccountModal} /> : ""}
      
        <div className="delete-account-wrapper">
          <ButtonComponent 
            text={'Eliminar Cuenta'}
            className="btn-tertiary"
            disabled={false}
            onClick={() => this.setState({ deleteAccountModal: openModalData }) }
          />
        </div>
      </>
    )
  }

  onAccountDeletionConfirm = async () => {
    const requestExistsModalData = {
      label: 'La solicitud ya existe',
      text: 'La solicitud de eliminación ya existe, Nos contactaremos contigo en las siguientes 10 días hábiles para eliminar tu cuenta.',
      onDismiss: () => { this.setState({ deleteAccountModal: null }) },
      isOpen: true,
      buttonText: 'Ok',
      buttonAction: () => { this.setState({ deleteAccountModal: null }) },
      hasError: true,
      buttonMargin:"-20px"
    }

    const requestErrorModalData = {
      label: 'Hubo un problema',
      text: 'Lo sentimos, ha ocurrido un error al enviar la solicitud, por favor contáctate con nosotros.',
      onDismiss: () => { this.setState({ deleteAccountModal: null }) },
      isOpen: true,
      buttonText: 'Ok',
      buttonAction: () => { this.setState({ deleteAccountModal: null }) },
      hasError: true,
    }

    const confirmModalData = {
      label:'Solicitud enviada',
      text: 'Nos contactaremos contigo en las siguientes 10 días hábiles para eliminar tu cuenta.',
      onDismiss: () => { this.setState({ deleteAccountModal: null }) },
      isOpen: true,
      buttonText: 'Ok',
      buttonAction: () => { this.setState({ deleteAccountModal: null }) },
      hasError: false,
    };

    this.setState({ deleteAccountModal: null }) 
    const res: any = await this.sendAccountDeletion();

    let nextModalData = null;

    if (res) {
      if (res.data.requestAlreadyExists) {
        nextModalData = requestExistsModalData
      } else {
        nextModalData = confirmModalData
      }

    } else {
      nextModalData = requestErrorModalData;
    }

    this.setState({ deleteAccountModal: nextModalData });
  }

  sendAccountDeletion = async () => {
    const { user } = this.props
    return await user.userAccountDeletion();
  }

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }

  render() {
    const { cartModel } = this.props
    const {
      user,
      form,
      offices,
      userInfo,
      edit,
      passworToupdate, 
      passwordConfirmationValue,
      newPasswordValue,
      appVersion,
      nextUpdate,
      unreadNotificationsUser,
      loadingCover,
      showOnboarding,
      onboarding,
      countryCode,
      initialFormData,
      cart,
      modifiedPassword,
      isOpenAddOffice,
      showViewOffice,
      officeSelected,
      branchOffice,
      pendingOffices
    } = this.state
    
    const disabledBtn =
      newPasswordValue === passwordConfirmationValue &&
      newPasswordValue !== '' &&
      passwordConfirmationValue !== '' &&
      newPasswordValue.length >= 6 &&
      passwordConfirmationValue.length >= 6
        ? true
        : false

    const notificationsBadge = unreadNotificationsUser > 0 ? ' ' : 0

    const userData = getLocalUserData()
    let userName = userData?.name || userData?.role?.name
    const officesLocal = localStorage.getItem('@offices')
    const officesParsed = officesLocal ? JSON.parse(officesLocal) : []
    const officeLocal = localStorage.getItem('currentOffice')
    const officeParsed = officeLocal ? JSON.parse(officeLocal) : null

    return (
      <IonPage className="home-page-profile ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <LoadingCover isLoading={loadingCover} />
        {showOnboarding && 
          <GlobalComponents.Onboarding
            onboarding={onboarding.steps}
            nextPagePath={'profile'}
            onclickNextPagePath={this.onShowOrRemoveOnboar}
            from="profile"
          />} 
        {!showOnboarding && 
          <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <>
              <p className="paragraph-3 categories-toolbar-text">Categorías</p>
              <ToolBar
                title={userName}
                office={officeParsed}
                offices={officesParsed}
                loadOnChangeOffice={this.loadByComuneId}
                primaryButtons={[
                  {
                    icon: bell,
                    key: 'notifications',
                    onClick: this.goToNotifications,
                    badge: notificationsBadge,
                  },
                  {
                    icon: cartToolbarIcon,
                    key: 'cart',
                    onClick: this.goToCart,
                    badge: cart,
                  }
                ]}
                secondaryButtons={[
                  {
                    key: 'category',
                    onClick: this.goToCategory,
                  },
                ]}
                officeModel={this.props.offices}
                cartModel={cartModel}
              />
            </>
          </IonHeader>}  

        {!user && (
          <div className="loading-profile">
              <LoadingComponent/>
          </div>
        )}
   
        {user && !showOnboarding &&  !showViewOffice.isOpenViewOffice && !branchOffice &&(
          <div className="profile-content-wrapper">

            <div className="container-info-user">

              {this.renderUserInfo()}
         
              {offices && (offices.length + pendingOffices.length) > 0 && (offices.length + pendingOffices.length) < 5 && this.renderOfficesInfo(offices)}
              {offices && (offices.length + pendingOffices.length) > 0 && (offices.length + pendingOffices.length) < 5 && countryCode !== "MX" && this.renderBtnAddOffice()}
              {offices && (offices.length + pendingOffices.length) >= 5 && countryCode !== "MX" &&
                <div className="profile-info-card location-container container-branch-offices-tittle">
                  <div><h6 className="no-margin" >Sucursales</h6></div>
                  <div className="icon-arrow" onClick={()=>this.branchOffice(true)}><IonIcon src={Arrow} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></div>
                </div>}
            </div>
            <div className="container-password profile-info-card margin-separation">
              {!passworToupdate && 
                <>
                  <div className="password-to-update">
                    <h5 className="password-title">Contraseña</h5>
                  </div>
                  <p className="paragraph-2 no-margin profile-info-text">**************</p>
                </>
              }
              {passworToupdate && (
                <div className="inputs-password-container">
                  <TextInput
                    onChange={(event:any) => this.onChangeNewPassword(event.target.value || '')} 
                    placeholder="Debe tener de 6 a 12 carácteres"
                    error={"La contraseña debe tener un mínimo de 6 carácteres"}
                    value={newPasswordValue}
                    type={this.state.showPassword ? "text": "password"}
                    isValid={!!this.validatePassword(newPasswordValue)}
                    toValidate={newPasswordValue}
                    maxLength={40}
                    autoComplete="off"
                    label="Contraseña"
                    iconDefault="icn_password" 
                    iconInvalid="icn_password-red"
                    iconValid="icn_password-blue"
                    showEyeIcon={true}
                    showPassword={this.state.showPassword}
                    handleShowPassword={this.handleShowPassword}
                  />
                  <TextInput
                    onChange={(event:any) => this.onChangePasswordConfirmation(event.target.value || '')} 
                    placeholder="Repite tu contraseña"
                    error={"La contraseña debe tener un mínimo de 6 carácteres"}
                    value={passwordConfirmationValue}
                    type={this.state.showConfirmPassword ? "text": "password"}
                    isValid={!!this.validatePassword(passwordConfirmationValue)}
                    toValidate={passwordConfirmationValue}
                    maxLength={40}
                    autoComplete="off"
                    label="Confirmar contraseña"
                    iconDefault="icn_password" 
                    iconInvalid="icn_password-red"
                    iconValid="icn_password-blue"
                    showEyeIcon={true}
                    showPassword={this.state.showConfirmPassword}
                    handleShowPassword={this.handleShowConfirmPassword}
                  />
                  {newPasswordValue !== passwordConfirmationValue &&
                    newPasswordValue !== '' &&
                    passwordConfirmationValue !== '' && (
                      <div className="alert-password">Ingresar la misma contraseña</div>
                  )}
                </div>
              )}
               <div className="info-update-btn-container">
                  <ButtonComponent 
                    text={passworToupdate ? (modifiedPassword ? "Guardar cambio" : "Cerrar") : "Cambiar contraseña"}
                    className="btn-tertiary w-100-btn"
                    onClick={passworToupdate && modifiedPassword ? this.savePassword : this.passworToupdate }
                    disabled={passworToupdate && modifiedPassword ? !disabledBtn : false}
                  />
                </div>
            </div>

            <div className="container-email-phone profile-info-card margin-separation">
              {this.renderContactInfoCondition(edit, userInfo, form, initialFormData, countryCode)}
            </div>

            {this.renderProfileOptions(countryCode)}

            {this.renderDeleteAccount()}

            {appVersion.update &&
              appVersion.url &&
              (appVersion.required || !nextUpdate || nextUpdate.nextUpdate <= new Date().getTime()) && (
                <div className="container-logout" onClick={this.goStore}>
                  {appVersion.buttonUpdate}
                </div>
              )}
          </div>
        )}
       
        <AlertModal 
          label={'Agregar sucursal'}
          text={'Tu solicitud será revisada y confirmada antes de que puedas utilizar la nueva dirección.'}
          buttonText={'Entiendo y acepto'}
          onDismiss={() => this.toCloseModal()}
          isOpen={isOpenAddOffice}
          buttonAction={() => this.toAcceptAddOffice()}
        />
        {showViewOffice.isOpenViewOffice && 
          <div >
            <OfficeUser
            office={officeSelected}
            closeOfficeUser= {this.closeViewOfficeUser}
            officeExtraData={showViewOffice}
            user={user}
            />
          </div>
        
        }
        {branchOffice && 
          <IonContent className="container-branch-office" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <div className="container-header">
              <div className="back-small-arrow" >
                <IonButton onClick={() => this.arrowBack()} mode='ios' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                  <IonIcon src={backSmallArrow} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonButton>
              </div>
              <div className="container-title">
                <h1 className="title">Cuenta</h1>
              </div>
            </div>
            {this.renderOfficesInfo(offices)}
            {countryCode !== "MX" && this.renderBtnAddOffice()}
          </IonContent>}
      </IonPage>
    )
  }
}

export default withRouter(Profile)