import React from 'react'
import { REACT_APP_BFF_IMAGE } from '../../../../config'
import './index.scss'

interface Category {
  _id: string
  name: string
  logo: string
}

const categories: Category[] = [
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },

  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
  {
    _id: '5d689399754b990022d3dfb3',
    name: 'Despensa',
    logo: 'icn_despensa.svg',
  },
]

function HomeFulfillmentCategories() {
  return (
    <div className="home-fulfillment-categories">
      {categories.map((category) => (
        <div key={category._id}>
          <img src={`${REACT_APP_BFF_IMAGE}categories/${category.logo}`} alt={category.name} />

          <h5>{category.name}</h5>
        </div>
      ))}
    </div>
  )
}

export default HomeFulfillmentCategories
