import React from 'react'
import { sendMetric } from '../firebase/firebaseTags'

// Icons
import { IonModal, IonIcon } from '@ionic/react'
import closeIcon from '../../assets/icons/nav_close_white_x.svg'

// Models
import User from '../../models/User'
import { ProductModel } from '../../models/ProductModel'
import { ProviderModel } from '../../models/ProviderModel'

// Deeplinks
import { manageBannerClick } from '../../utils/deeplinks'

// css
import './PromotionModal.scss'
interface Props {
  user: User
  history: any
  isOpenPromotion: boolean
  promotionModal: any
  closePromotionModal: any
  productModel: ProductModel
  providerModel: ProviderModel
  validateDeeplinks?: () => void
}

interface State {
  recoveryPassword: boolean
}

export default class PromotionModal extends React.PureComponent<Props, State>{

  onImageClick = async () => {
    const { history, validateDeeplinks, promotionModal } = this.props  
    await manageBannerClick(promotionModal, history, validateDeeplinks)
  }
  
  componentDidMount() {
    sendMetric('view_banner_fullscreen')
  }

  closeModal = (promotionModal: any) => {
    sendMetric('close_banner_fullscreen')
    this.props.closePromotionModal(promotionModal)
  }

  render(){
    const { isOpenPromotion, promotionModal } = this.props

    return (
      <IonModal cssClass="promotion-modal-full-screen" backdropDismiss={false} isOpen={isOpenPromotion}>
        <div className="content">
            <div className="header">
              <div className="close-banner">
                <IonIcon icon={closeIcon} onClick={() => this.closeModal(promotionModal)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  />
              </div>
            </div>
            <div 
              className="container-img" 
              style={{ backgroundImage :  `url(${process.env.REACT_APP_BFF_IMAGE}${promotionModal.image.url})`}} 
              onClick={this.onImageClick}  
            >
            </div>
        </div> 
      </IonModal>
    );
  }
}
