import { IonSlide, IonSlides } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router';
import { getHomeBrands, THomeBrand } from '../../clients/brands';
import BrandIcon from '../brand-icon/BrandIcon';
import './HomeBrandsSliderStyle.scss'

const brandsSlidesOptions: any = {autoHeigth: true, slidesPerView: 'auto'}

type THomeBrandsSliderProps = RouteComponentProps;

const HomeBrandsSlider: React.FC<THomeBrandsSliderProps> = (props) => {
  const [homeBrands, setHomeBrands] = useState<THomeBrand[]>([]);
  const {history} = props;

  useEffect(() => {
    async function loadHomeBrands() {
      const homeBrandsResult = await getHomeBrands()
      setHomeBrands(homeBrandsResult)
    }

    loadHomeBrands()
  }, [])

  if (!homeBrands.length) return null

  return (
    <>
      <div className="container-distributo">
        <h1 className="providers-section-title">Marcas</h1>
      </div>
      <div className='container-distributo home-brands-container'>
        <IonSlides pager={false} scrollbar={false} options={brandsSlidesOptions} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {homeBrands.map(({_id, urlLogo, name}) => {
            return (
              <IonSlide key={_id} className='slide-home-brand' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <BrandIcon
                  urlLogo={urlLogo}
                  handleAction={() => history.push(`/home-brands-detail/${_id}`, {name})}
                />
              </IonSlide>
            )
          })}
        </IonSlides>
      </div>
    </>
  )
}

export default withRouter(React.memo(HomeBrandsSlider))
