import { IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton } from '@ionic/react'
import React, { useState } from 'react'
import NavbarHomeProviders from '../../components/navbar-home-providers'
import './index.scss'
import HomeFulfillmentCategories from './components/home-fulfillment-categories'

const segments: string[] = ['Destacado', 'Categorías', 'Información']

function HomeFulfillment() {
  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0])

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <NavbarHomeProviders />

        <div className="home-fulfillment-content">
          <IonSegment mode="md" value={selectedSegment} onIonChange={(e) => setSelectedSegment(e.detail.value!)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {segments.map((segment) => (
              <IonSegmentButton value={segment} style={{ '--color-checked': '#1E3799' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonLabel className={selectedSegment === segment ? 'isChecked' : ''} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{segment}</IonLabel>
              </IonSegmentButton>
            ))}
          </IonSegment>

          {selectedSegment === segments[1] && <HomeFulfillmentCategories />}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default HomeFulfillment
