import React, {Fragment} from 'react'
import {
  IonPage,
  IonContent,
  IonToast
} from '@ionic/react'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel, Product } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { ProviderModel } from '../../models/ProviderModel'
import { namePayTitle } from '../../utils/orders'
import { formatCurrency } from '../../utils/intl'
import moment, { locales } from 'moment'

import { reversarBancoEstado } from '../../clients/order'
import { eventWithName, sendMetric } from '../firebase/firebaseTags'
import arrowBack from './../../assets/icons/arrow-back-blue.svg'
import actClose from './../../assets/icons/act_close.svg'
import actOpen from './../../assets/icons/act_open.svg'
import iconStar from './../../assets/icons/icn_star.svg'
import actRight from './../../assets/icons/act_right.svg'
import menuIcon from './../../assets/icons/act_menu.svg'

import OrderProduct from '../order/OrderProduct'
import { totalPrice } from '../../clients/product'
import { getSurveyByKey } from '../../utils/survey'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import ButtonSelect from '../../components/button-select/ButtonSelect'
import ButtonIcon from '../../components/button-icon-component/ButtonIcon'
import ConfirmOrder from '../../pages/confirm-order/ConfirmOrder'
import OrderUserInfo from './OrderUserInfo'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import ButtonComponent from '../../components/basic-button/ButtonComponent'
import LoadingCoverUserGuest from '../../components/loading-cover/LoadingCoverGuestUser'
import AlertModal from '../../components/modals/AlertModal'
import './Order.scss'
import HelpOptionsModal from '../../components/help-options-modal/HelpOptionsModal'
import { isProviderActive } from '../../utils/validateCurrentOffice'
import { navigateToCart } from '../../utils/navigation'

type IPathParams = {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  readonly orders: any
}

interface State {
  modalOpen?: { readonly providerId: string; readonly sku: string }
  order: any
  originalOrder: any
  products: {
    [key: string]: {
      [key: string]: Product | 'Loading'
    }
  }
  isInfoProduct: boolean
  productOrder: any
  spinner: boolean
  showToast: boolean
  orderStatesToShow: any
  shouldShowAllStates: boolean
  isInfoUser: boolean
  showModalConfirmOrder: boolean
  isLoadingCoverUserGuest: boolean
  showAlert: boolean
  error: boolean
  isHelpModalOpen: boolean,
  helpOptions: any[],
  showButtonSelect: boolean
}

class OrderInfo extends React.PureComponent<IProps, State> {
  state: State = {
    products: {},
    order: {},
    originalOrder: {},
    isInfoProduct: false,
    productOrder: {},
    spinner: false,
    showToast: false, 
    orderStatesToShow: [],
    shouldShowAllStates: false,
    isInfoUser: false,
    showModalConfirmOrder: false,
    isLoadingCoverUserGuest: false, 
    showAlert: false,
    error: false,
    isHelpModalOpen: false,
    helpOptions: [],
    showButtonSelect: false
  }
  componentDidMount = async () => {
    const order: any = this.props.history.location.state
    this.setState({ originalOrder : order})
    const updatedOrder = await this.props.orders.findOne(order._id, true)
    
    const indexPlanificado = order.status.findIndex((s: any) => s.name === "Pendiente")
    const statusFilter = order.status.slice(indexPlanificado)
    this.setState({ showButtonSelect: statusFilter.length > 1 })

    this.fetchHelpOptions(updatedOrder)
 
    this.setState({ order: updatedOrder})
    
    setTimeout(async ()=>{
      if(order.state === 'Entregado'){
        const orders = await this.props.orders.repeatProduct(order.orderNumber)
        this.setState({
          productOrder:orders
        })
    }
    }, 500)

    this.setState({
      orderStatesToShow: [updatedOrder?.status[updatedOrder.status.length - 1]],
    });
   
    const { cartModel, productModel } = this.props
    const cartProducts = cartModel.getCart()
    if (cartProducts.length === 0) {
      return
    }
    for (const { providerId, sku } of cartProducts) {
      this.setState(
        ({ products }) => ({
          products: { ...products, [providerId]: { ...products[providerId], [sku]: 'Loading' } },
        }),
        () => {
          productModel.getByProviderAndSku(providerId, sku).then(product => {
            if (product) {
              this.setState(({ products }) => {
                return { products: { ...products, [providerId]: { ...products[providerId], [sku]: product } } }
              })
            }
          })
        },
      )
    }
  }

  fetchHelpOptions = async (order: any) => {
    try {
      if (order?.hasActiveIncident) return
      const orderStatus: string = order.state
      const checkCouponStatuses = ['Rechazado', 'Anulado'];
      const hasCoupon = Boolean(order?.discounts?.length)
      
      let helpOptions = await this.props.orders.findHelpOptions(orderStatus)

      if (helpOptions) {
        if (checkCouponStatuses.includes(orderStatus) && !hasCoupon) {
          helpOptions = helpOptions.filter(({ type }: any) => type !== 'recover-coupon'); 
        }

        this.setState({ helpOptions })
      }
    } catch (error) {
      console.error(error)
    }
  }

  effectiveProductPrice = (product: any, quantity: any) => {
    const { discount, price } = product

    if (!discount) return price
    if (typeof discount === 'number') return price - discount
    if (discount.length === 0) return price
    const fullfilled = discount.findIndex((step: any) => quantity < step.quantity)
    if (fullfilled === -1) {
      return price - discount[discount.length - 1].discount
    }
    if (fullfilled === 0) {
      return price
    }
    return price - discount[fullfilled - 1].discount
  }

  getCart: any = (order: any) => {
    if (order && order.products) {
      const providerId = order.providerId
      return order.products.map((product: any) => {
        const { name, filename, price, sku, quantity, units, packageType, id, infoPrice } = product
        return {
          brand: '',
          name,
          filename,
          price,
          providerId,
          sku,
          quantity,
          units,
          packageType,
          id,
          infoPrice,
        }
      })
    } else {
      return []
    }
  }

  getDiscounts: any = (order: any) => {
    if (order && order.discounts) {
      return order.discounts.map((discount: any) => {
        const { provider, applyDiscount, realDiscount, code, discountType, amount } = discount

        if (applyDiscount) {
          return {
            realDiscount,
            applyDiscount,
            code,
            discountType,
            amount,
            provider,
          }
        } else {
          return false
        }
      })
    } else {
      return false
    }
  }

  reversar = async (buyOrder: any) => {
    const res = await reversarBancoEstado({ buyOrder })
    if (res.reverse) {
      alert('Reversa realizada')
    } else {
      alert('No es posible la reversa')
    }
  }

  goToAnulateOrder = (order:any) => {
    sendMetric("cancel_order")
    this.props.history.push('/order-delete', order)
  }

  goToProductsOrder = (order:any) => {
    sendMetric("products_order")
    this.props.history.push('/order-product', order)
  }

  showInfoProduct = () => {
    const { isInfoProduct } = this.state
    this.setState({
      isInfoProduct:!isInfoProduct,
      isInfoUser:false
    })
  }

  showInfoUser = () => {
    const { isInfoUser } = this.state
    this.setState({
      isInfoUser:!isInfoUser,
      isInfoProduct:false
    })
  }
  
  repeatOrder = async () =>{
    const { productOrder } = this.state
    const providersLocalStorage = localStorage.getItem("@providers") 
    const  providers = providersLocalStorage ? JSON.parse(providersLocalStorage) : null
  
    this.setState({spinner:true})
    let paymentFormProvider:any = ''
    if(Object.keys(productOrder).length > 0  && productOrder.products.length > 0) {

      paymentFormProvider =  Object.values(providers).find((item:any) => 
      productOrder.products.find((product:any) => item.id ===  product.providerId ))
      
      for (let index = 0; index < productOrder.products.length; index++) {
        const item = productOrder.products[index];
        await this.props.cartModel.setProduct({
          sku:item.sku,
          providerId:item.providerId,
          price: (await totalPrice(item._id, item.quantity)).price,
          quantity: item.quantity,
          units:item.units,
          packageType:item.packageType,
          infoPrice:await totalPrice(item._id, item.quantity),
          paid:false,
          paymentForm: paymentFormProvider.paymentFormDefault,
          name:item.name,
        })
      }

      this.setState({ spinner: false, showToast: true })
      sendMetric("view_reorder")

      setTimeout(() => {
        navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state);
      }, 900);
    }
  }

  renderStatusDetail = (item:any) => {
    const { order } = this.state

    return (
      <div className="detail">
        <div className="status-title">
          <div className='circle-state-order'  style={{background:`${item.color}`}}></div>
            {this.returnDivStatus(item)}
        </div>
        {item.comments &&
          item.comments.map((comment:any) =>(
            <div className="container-comment">
              <div className="comment">{moment.utc(new Date(comment.date)).format('DD-MM-YYYY')} - {moment(comment.date).format('HH:mm')}</div>
              <div className="comment">{`${item.name === 'Planificado' ? '' : 'Motivo: '} ${comment.comment}`}</div>
            </div>
          ))
        }
        { item.name === 'Pendiente' && order.paymentMethodsType === 'pre-pago' && 
          <div className="comment">Pagado</div>
        }
      </div>
    )
  }

  returnDivStatus = (item:any) => {
    return (
      <div>
        {(item.name === 'Planificado' && item.estimatedDate === undefined ) || (item.estimatedDate === '') ?
          <div>
            <div>{moment.utc(new Date(item.date)).format('DD-MM-YYYY ')} - {moment(item.date).format('HH:mm')}</div>
            <div>{item?.name}</div>
            <div>{`entre ${moment.utc(new Date(item.startDate)).format('DD-MM-YYYY ')} al ${moment.utc(new Date(item.finishDate)).format('DD-MM-YYYY ')}`}</div>
            <div>{item.comment ? item.comment : ''}</div>
          </div> :
          (item.name === 'Planificado' && item.estimatedDate && item.estimatedDate !== '') ?
          <div>
            <div>{moment.utc(new Date(item.estimatedDate)).format('DD-MM-YYYY')} - {moment(item.startDate || item.date).format('HH:mm')}</div>
            <div>{item?.name}</div>
            <div>{item.comment ? item.comment : ''}</div>
          </div> 
          : <div>
              <div>{moment.utc(new Date(item.date)).format('DD-MM-YYYY')} - {moment(item.startDate || item.date).format('HH:mm')}</div>  
              <div>{item?.name}</div>
              <div>{item.comment ? item.comment : ''}</div>
            </div>
        }
      </div>
    )
  }

  even = (element:any) => { 
    const surveyData = getSurveyByKey('nps-survey')

    return element.key === surveyData.value;
  }
  
  goToSurvey = () => { 
    const { order } = this.state
    const { _id, providerId } = order
    const { history } = this.props
    const surveyData = getSurveyByKey('nps-survey')
    
    history.push('/survey-nps', {
      targetType: 'order',
      targetId: _id,
      key: surveyData.value,
      vendorId: providerId
    }) 
  }

  onClickBtnShowMore = () => {
    const { shouldShowAllStates, order } = this.state

    const indexPlanificado = order.status.findIndex((s: any) => s.name === "Pendiente")
    const statusFilter = order.status.slice(indexPlanificado)

    this.setState({
      shouldShowAllStates: !shouldShowAllStates,
      orderStatesToShow: shouldShowAllStates ? [order?.status[order.status.length - 1]] : statusFilter
    })
  }
  
  orderRepeat = async () => {
    const { order } = this.state

    this.setState({isLoadingCoverUserGuest: true})
    eventWithName('click_repeat_order')

    try {
      const repeatOrder = await this.props.orders.repeatOrder(order._id)
      
      if(Object.keys(repeatOrder).length > 0 && repeatOrder.data.respuesta === 'repeat_sin_stock') {
        this.setState({isLoadingCoverUserGuest: false})
        this.setState({showAlert:true, error:true})
      }

      if(Object.keys(repeatOrder).length > 0 && repeatOrder.data.respuesta === 'repeat_completado') {
        this.setState({isLoadingCoverUserGuest: false})
        navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state); 
      }

      if(Object.keys(repeatOrder).length > 0 && repeatOrder.data.respuesta === 'repeat_faltan_productos') {
        this.setState({isLoadingCoverUserGuest: false})
        localStorage.setItem("repeatOrder", JSON.stringify(repeatOrder.data.respuesta)) 
        navigateToCart(this.props.history, this.props.location.state || this.props.history.location.state); 
      }

    } catch (error) {
      console.error("Error", error)
    }
  } 

  closeModal = () => this.setState({showAlert:false})

  setHelpModalOpen = (isOpen: boolean) => this.setState({ isHelpModalOpen: isOpen })

  onOptionClick = (option: any, order: any) => this.props.history.push('/order-help', { option, order })

  renderOrderButtons = (order: any) => {
    const isFinished = order && order.state === "Entregado"
    const { isDeliverable } = order
    const hasHelpQuestions = Boolean(this.state.helpOptions?.length)

    if (!isFinished && !isDeliverable && !hasHelpQuestions) return <></>

    return (
      <div className="container-btn-repeat">

        {isFinished &&
          <div className="order-button-wrapper">
            <ButtonComponent 
              className={`btn-primary btn-standard-width`}
              onClick={() => this.orderRepeat()}
              text={"Repetir pedido"}
            />
          </div>
        }

        {isDeliverable &&
          <div className="order-button-wrapper">
            <ButtonComponent 
              className={`btn-primary btn-standard-width`}
              onClick={() => this.setState({ showModalConfirmOrder: true })}
              text={"Confirmar entrega"}
            />
          </div>
        }
    
        {hasHelpQuestions && 
          <div className="order-button-wrapper btn-standard-width">
            <ButtonComponent
              className="btn-secondary"
              onClick={() => { this.setHelpModalOpen(true) }}
              text="¿Necesitas ayuda?"
            />
          </div>
        }

      </div>
    )
  }

  render() {
    const { originalOrder, order, isInfoProduct, showToast, orderStatesToShow, shouldShowAllStates, isInfoUser, showModalConfirmOrder, 
      isLoadingCoverUserGuest, showAlert, error, isHelpModalOpen, helpOptions, showButtonSelect } = this.state
    const { history } = this.props
    const productsInCart = this.getCart(order)
    const length = productsInCart.length
    const surveyData = getSurveyByKey('nps-survey')
    const loadingPhrase = ['Buscando productos', 'Actualizando precios', 'Actualizando precios']
    const infoVendor = !originalOrder?.provider ? null : originalOrder.provider
    const VENDOR_NOT_ANNULABLE = [process.env.REACT_APP_DESPACHO]
    
    return (
      <IonPage className="cart-page-order ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="container-header-without-toolbar">
          <HeaderWithoutToolbar 
            icon={arrowBack}
            text="Tus pedidos"
            onClick={() => history.push('/orders')}
          />
        </div>
        {/* Without items */}
        {length === 0 && (<LoadingComponent/>)}
        {/* With items */}
        {length > 0 && !isLoadingCoverUserGuest && (
          <IonContent className="container-order-view" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {order.state === 'Entregado' && surveyData && !order.hideSurvey && !order.surveysAnswered?.some(this.even) &&
              <div className='btn-repeat-order'>
                <ButtonIcon 
                  text="Califica tu pedido"
                  onPressBtn={this.goToSurvey}
                  icon={iconStar}
                />
              </div>
            }
            <div className="container-name-btn">
              <h4 className='no-margin'>{infoVendor?.alias || 'Proveedor'}</h4>
            </div>
            <div className='container-order'>
              <div className='title-order'>
                <h5>Orden {order?.orderNumber}</h5>
              </div>
              <div className='date-order'>
                Fecha {moment(new Date(order.orderedAt)).format('DD-MM-YYYY')}  {' '}
                Hora {moment(order.orderedAt).format('HH:mm:ss')}
              </div>
            </div>
            <div className='container-state' >
              <div className='title-state'>
                <div className='title-state-order'>
                  <h5>
                    Estado del pedido
                  </h5>
                </div>
              </div>
              <div className='div-state'>
                {orderStatesToShow?.map((item:any, k:any, array:any) => {
                  return (
                    <React.Fragment key={k}>
                      {
                        <div className='state-order'>
                          {this.renderStatusDetail(array[array.length - 1 - k])}
                        </div>
                      }
                    </React.Fragment>
                  )
                  })
                }
                { showButtonSelect  &&
                  <ButtonSelect 
                    onPressBtn={this.onClickBtnShowMore} 
                    isOpen={shouldShowAllStates}
                  />
                }
              </div>
            </div>
            <div className='info'>
              <div className='summary'>Resumen</div>
              <div className='info-aditional'>{namePayTitle(order)}</div> 
              <div className='name-provider-info'>
                {infoVendor?.alias || 'Proveedor'}
                <div className='total-amount'>{order &&  formatCurrency(order.totalProductsPrice) }</div>
              </div>
              {order.shippingCostApplied &&
                <div className='info-wrapper'>
                  <div>
                    Costo de despacho
                  </div>
                  <div>
                    {order.shippingCostProvider > 0.1 ? formatCurrency(order.shippingCostProvider) : "¡Gratis!"}
                  </div>
                </div>
              }
              { order.provider.alias.toLowerCase().includes("coliseo") ?
                <div className='info-aditional-delivery-date'>
                  {`${moment(order.orderedAt).hours() >= 16 ? moment(order.orderedAt).add(2, 'days').format('ddd DD/MM/YYYY') : moment(order.orderedAt).add(1, 'days').format('ddd DD/MM/YYYY')}`}
                </div>
              :
                infoVendor?.deliveryData?.default?.days &&
                  <div className='info-aditional-days'>
                    Entrega {infoVendor.deliveryData.default.days} días
                  </div>
              }
              <div className='container-discount'>
                <div className='title-discount'>Descuentos</div>
                {order && order.discounts.length > 0 ? 
                  order.discounts.map((item:any, index: number) =>{
                    return (
                      item.discountType !== "pay-promo" && <div className='total-discount' key={index}>{ formatCurrency(item.realDiscount)}</div>
                    )
                  })
                :  
                  <div className="container-total-without-value"> 
                    <div>---</div>
                    <div>0</div>
                  </div>
                }
              </div>
              <div className='container-total' >
                <div>TOTAL</div>
                <div>{order &&  formatCurrency(order.finalPrice) }</div>
              </div>
            </div>
            <div className="product-list-order">
              <Fragment >
                <div className="provider-summary-order">
                  <div onClick={this.showInfoProduct} className={`provider-header-order ${isInfoProduct ? 'open-details-products' : ''}`}>
                    <div className="container-provider-header">
                      <div className="provider-title">
                        Productos
                      </div>
                      <div className="icon-provider">
                        <img className={`${isInfoProduct ? 'open-products-details' : 'close-products-details'}`} src={isInfoProduct ? actClose : actOpen}/>
                      </div>
                    </div>
                  </div>
                  { isInfoProduct &&
                    <OrderProduct 
                      orderUser={order}
                      {...this.props}
                    />
                  }
                </div>
              </Fragment>
              <Fragment >
                <div className="provider-summary-order">
                  <div onClick={this.showInfoUser} className={`provider-header-order ${isInfoUser ? 'open-details-products' : ''}`}>
                    <div className="container-provider-header">
                      <div className="provider-title">
                        Información
                      </div>
                      <div className="icon-provider">
                        <img className={`${isInfoUser ? 'open-products-details' : 'close-products-details'}`} src={isInfoUser ? actClose : actOpen}/>
                      </div>
                    </div>
                  </div>
                  { isInfoUser &&
                    <OrderUserInfo
                      orderUser={order}
                      {...this.props}
                    />
                  }
                </div>
              </Fragment>
              { !VENDOR_NOT_ANNULABLE.includes(infoVendor._id) && originalOrder.isAnnulable &&
                <Fragment >
                  <div className="provider-summary-order">
                    <div onClick={() => this.goToAnulateOrder(order)} className={`provider-header-order ${isInfoProduct ? 'open-details-products' : ''}`}>
                      <div className="container-provider-header">
                        <div className="provider-title">
                          Anular
                        </div>
                        <div className="icon-anulate-order">
                          <img src={actRight}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              } 
              {showModalConfirmOrder &&
                <ConfirmOrder 
                  route={'order'} 
                  selectOrder={order}
                  showConfirmOrder={() => this.setState({ showModalConfirmOrder: true })}
                  onBackdropDismiss={() => this.setState({ showModalConfirmOrder: false })}
                  {...this.props} 
                />}
            </div>
            <IonToast 
              isOpen={showToast}
              mode="ios"
              cssClass="addedToCart"
              message="Producto agregado al carro"
            />
            {showAlert &&
              <AlertModal 
                label={'No podemos crear el carro'}
                text={"Ninguno de los productos en la lista posee stock disponible."}
                buttonText={"Entiendo"}
                buttonAction={this.closeModal}
                isOpen={showAlert}
                onDismiss={this.closeModal}
                icon={menuIcon}
                hasError={error || false}
              />
            }
        </IonContent>
        )}

        {length > 0 && this.renderOrderButtons(order)}

        <HelpOptionsModal 
          isOpen={isHelpModalOpen}
          title="¿Cómo podemos ayudarte?" 
          options={helpOptions}
          setOpen={this.setHelpModalOpen}
          onOptionClick={(option) => { this.onOptionClick(option, order) }}
        />

        {isLoadingCoverUserGuest && 
          <LoadingCoverUserGuest 
            isLoading={isLoadingCoverUserGuest}
            title={"Estamos armando el carro nuevamente"}
            phrase={loadingPhrase}
            route="order"
          />
        }

        <style>
          {`
            .footer-menu-home {
              display: none !important;
            }
          `}
        </style>
      </IonPage>
    )
  }
} 

export default withRouter(OrderInfo)
