import React from 'react'
import './PromotionSlider.scss'
import { IonSlides } from '@ionic/react'
import { Promotion } from '../../models/PromotionModel'
import { ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import PromotionCard from '../promotion-card/PromotionCard'
import { trackBannersActions } from '../../pages/firebase/firebaseTags'

interface IProps {
  promotions: Promotion[]
  slidesPerView?: number
  cartModel: CartModel
  productModel: ProductModel
  history: any
  providers: any
  timePerSlide?: number | null
  typeBanners: string
  validateDeeplinks?: () => void
}

interface IState {
  isOpen: boolean
  showToast: boolean
  swiperRef: any,
  isOnePromo: boolean
}

export default class PromotionSlider extends React.PureComponent<IProps, IState> {
  state: IState = {
    isOpen: false,
    showToast: false,
    swiperRef: null,
    isOnePromo: true,
  }

  
  componentDidMount() {
    this.checkOnePromo()
  }
  
  componentWillReceiveProps() {
    this.checkOnePromo()
  }
  
  checkOnePromo = () => {
    const isOnePromo = this.props.promotions?.length === 1
    this.setState({ isOnePromo })
  }

  registerFirstImpression = (swiperElement: any) =>  {
    if (!swiperElement) return;
    const targetSlide = `slide-0`
    const bannerAction = 'ad_banner_impression'
    const slideElement = document.querySelector(`.${targetSlide}`)
    const position = swiperElement.getBoundingClientRect()
    let providerName = ''
    let bannerType = ''
    let bannerId = ''
    let bannerName  = ''    
    
    if(slideElement && (position.top >= 0 && position.bottom <= window.innerHeight)) {
      providerName = slideElement.getAttribute('data-provider-name') || ''
      bannerType = slideElement.getAttribute('data-banner-type') || ''
      bannerId = slideElement.getAttribute('data-provider-id') || ''
      bannerName = slideElement.getAttribute('data-banner-name') || ''
      trackBannersActions(bannerAction, bannerType, providerName, bannerId, 'home', bannerName)
      this.setState({ swiperRef: swiperElement })
    }
  }
  
  setSwiperRef = (el: any) => { 
    setTimeout(()=> {
      if(!this.state.swiperRef) this.registerFirstImpression(el)
    }, 3000)
  }

  render() {
    const { promotions, cartModel, productModel, history, providers, typeBanners, validateDeeplinks } = this.props
    const { isOnePromo } = this.state

    const options = {
      allowTouchMove: !isOnePromo,
      slidesPerView: isOnePromo ? 1 : 1.2,
      spaceBetween: 20,
      preloadImages: true,
      autoplay: isOnePromo ? false : {
        delay: this.props.timePerSlide || 5000 + Math.random() * 2000,
        disableOnInteraction: false,
      },
      on: {
        activeIndexChange() {
          const swiper: any = this
          let element = swiper.el
          
          if(element) {
            let position = element.getBoundingClientRect();
          
            if(position.top >= 0 && position.bottom <= window.innerHeight) {
              const targetSlide = `slide-${swiper.activeIndex}`
              const bannerAction = 'ad_banner_impression'
              const slideElement = element.getElementsByClassName(targetSlide)[0]
              
              let providerName = ''
              let bannerType = ''
              let bannerId = ''
              let bannerName  = ''
  
              if(slideElement) {
                providerName = slideElement.getAttribute('data-provider-name')
                bannerType = slideElement.getAttribute('data-banner-type')
                bannerId = slideElement.getAttribute('data-provider-id')
                bannerName = slideElement.getAttribute('data-banner-name')
  
                trackBannersActions(bannerAction, bannerType, providerName, bannerId, 'home', bannerName)
              }
            }
          }
        },
      }
    }

    return (
      <div className="product-slider-promotion" ref={this.setSwiperRef}>
        <IonSlides options={options} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {promotions.map((promotion, index) => ( 
              <PromotionCard
                key={promotion._id}
                cartModel={cartModel}
                productModel={productModel}
                promotion={promotion}
                history={history}
                providers={providers}
                index={index}
                typeBanners={typeBanners}
                validateDeeplinks={validateDeeplinks}
              />
            ))
          }
        </IonSlides>
      </div>
    )
  }
}

