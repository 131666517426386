import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import Axios from 'axios'
import moment from 'moment'
import User from '../../models/User'
import { getCountryCode } from '../../utils/countriesTexts'
import { getLocalStorageObject, getLocalUserData } from '../../utils/localstorageData'
import { isValidUrl } from '../../utils/urls'

const qs = require('qs')
const axiosInstance = Axios.create()

declare const window: any

const appId = process.env.REACT_APP_FACEBOOK_APP_ID
const appSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET
const advertiserId = process.env.REACT_APP_FACEBOOK_ADVERTISER_ID

FirebaseAnalytics.initializeFirebase({
  apiKey: `${process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_ANALYTICS_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKER}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID}`,
}).catch(() => {
  console.log('Firebase initialization not necessary, platform is not web')
})

const defaultCurrency = () => 'CLP'

let eventsIdFlag = true

const userEventsId = async () => {
  const userLocal = localStorage.getItem('@user')
  if (!userLocal) return ''

  let eventsId = localStorage.getItem('userEventsId')

  if (!eventsId && eventsIdFlag) {
    eventsIdFlag = false
    const user = new User()
    const userData = await user.getUserMe()
    const updatedUserEventId = userData?.eventId || ''
    eventsId = updatedUserEventId
    if (updatedUserEventId) localStorage.setItem('userEventsId', updatedUserEventId)
  }

  return eventsId || ''
}

const findOriginPath = (originLink: any) => {
  try {
    const data = [
      { key: ['home-providers'], value: 'vendor' },
      { key: ['provider'], value: 'category' },
      { key: ['cart'], value: 'pre_checkout' },
      { key: ['results'], value: 'search' },
      { key: ['vendor', 'search'], value: 'home_vendor' },
      { key: ['vendor', 'brand'], value: 'brand' },
      { key: ['list-offers'], value: 'catalog' },
      { key: ['favorites'], value: 'provider_favorite_products' },
    ]

    const matchOriginPath = data.find((originFromPath) =>
      originFromPath?.key?.every((requiredPath) => originLink?.includes(requiredPath)),
    )

    if (!matchOriginPath) return originLink ? originLink[1] : ''
    return matchOriginPath.value
  } catch (error) {
    console.error(error)
    return ''
  }
}

const getCountryCurrency = () => {
  const countryObj = JSON.parse(localStorage.getItem('countryName') || '')
  return countryObj?.currency
}

export const sendMetric = async (eventName: string) => {
  await FirebaseAnalytics.logEvent({ name: eventName, params: { user_events_id: await userEventsId() } })
}

export const sendMetricWithSource = async (eventName: string, source: string) => {
  await FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      source,
      user_events_id: await userEventsId(),
    },
  })
}

export const clickNotificationEvent = async (notificationId: any, message: any) => {
  await FirebaseAnalytics.logEvent({
    name: 'click_notification',
    params: {
      notification_id: notificationId,
      message,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewAllOrdersEvent = async (numOrders: any, orderState: any) => {
  await FirebaseAnalytics.logEvent({
    name: 'view_orders',
    params: {
      num_orders: numOrders,
      order_state: orderState,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewProviderCategoriesEvent = async (vendorId: any, vendorName: any) => {
  await FirebaseAnalytics.logEvent({
    name: 'view_categories',
    params: {
      source: 'vendor',
      vendor_id: vendorId,
      vendor_name: vendorName,
      user_events_id: await userEventsId(),
    },
  })
}

export const seeOrderDetailsEvent = async (orderId: any, vendorId: any, vendorName: any, dateOrdered: any) => {
  await FirebaseAnalytics.logEvent({
    name: 'see_details',
    params: {
      order_id: orderId,
      id_vendor: vendorId,
      vendor_name: vendorName,
      date_ordered: dateOrdered,
      user_events_id: await userEventsId(),
    },
  })
}

export const cancelOrderEvent = async (orderId: any, vendorId: any, vendorName: any, dateOrdered: any) => {
  const dateCancelled = new Date()

  await FirebaseAnalytics.logEvent({
    name: 'order_canceled',
    params: {
      order_id: orderId,
      vendor_id: vendorId,
      vendor_name: vendorName,
      date_ordered: dateOrdered,
      date_cancelled: dateCancelled.toISOString(),
      user_events_id: await userEventsId(),
    },
  })
}

export const okInvalidRut = async (eventName: string) => {
  FirebaseAnalytics.enable()
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      user_events_id: await userEventsId(),
    },
  })
}

export const eventWithName = async (eventName: string, extraParams?: any) => {
  let params = {
    user_events_id: await userEventsId(),
  }

  if (extraParams) {
    params = {
      ...params,
      ...extraParams,
    }
  }

  FirebaseAnalytics.logEvent({
    name: eventName,
    params,
  })
}

export const trackBannersActions = async (
  eventName: string,
  bannerType: string,
  providerName?: string,
  bannerId?: any,
  source?: any,
  bannerName?: any,
) => {
  await FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      source,
      banner_id: bannerId,
      banner_type: bannerType,
      banner_name: bannerName,
      vendor: providerName,
      user_events_id: await userEventsId(),
    },
  })
}

export const eventForCart = async (
  eventName: string,
  productName: string,
  productId: string,
  brand?: string,
  category?: string,
  source?: any,
) => {
  const user_events_id = await userEventsId()

  await FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      source: source || '',
      item_name: productName,
      item_id: productId,
      brand: brand ?? '',
      category: category ?? '',
      user_events_id,
    },
  })
}

export const checkoutEvent = async (
  eventName: string,
  couponsArr: any,
  totalValue: any,
  groupedProducts: any,
  paymentMethod: any,
  comments: any = '',
) => {
  const coupons: any[] = []
  const discounts: any[] = []
  const currency = getCountryCurrency() || defaultCurrency()
  const vendors: any[] = []
  const productsIds: any[] = []
  let productsQuantity = 0
  let paymentMethodType = ''
  if (paymentMethod === 'pre-pago') paymentMethodType = 'anticipado'
  if (paymentMethod === 'post-pago') paymentMethodType = 'contra_entrega'

  couponsArr.forEach((couponObj: any) => {
    if (couponObj.code) coupons.push(couponObj.code)
    if (couponObj.totalDiscount) discounts.push(couponObj.totalDiscount)
  })

  Object.values(groupedProducts).forEach((group: any) => {
    group.forEach((product: any) => {
      const { providerId, quantity, _id } = product
      vendors.push(providerId)
      productsIds.push({ value: _id, vendorId: providerId })
      productsQuantity += quantity
    })
  })

  const fromPushNotif = isGeneratedFromPushNotif('product', productsIds, true)

  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      coupon: coupons.toString(),
      currency,
      value: totalValue,
      quantity: productsQuantity,
      vendor: vendors.toString(),
      discounts: discounts.toString(),
      payment_method: paymentMethodType,
      comments,
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const eventAddToCart = async (
  quantityProducts: number,
  product: any,
  valueProduct: number,
  providerName?: string,
  originPath?: any,
  hardSource?: any,
) => {
  const currency = getCountryCurrency() || defaultCurrency()
  let source = hardSource
  if (!hardSource) source = findOriginPath(originPath)

  const fromPushNotif = isGeneratedFromPushNotif('product', { value: product.id, vendorId: product.providerId }, false)

  FirebaseAnalytics.logEvent({
    name: 'add_to_cart',
    params: {
      source,
      quantity: quantityProducts,
      item_category: product.category,
      item_name: product.name,
      item_id: product.id,
      value: valueProduct,
      currency,
      vendor: providerName,
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const fbSignUpEvent = async () => {
  try {
    if (!appId || !appSecret || !advertiserId) return

    const accessTokenRes: any = await axiosInstance.get(
      `https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`,
    )
    if (accessTokenRes?.data?.access_token) {
      const params: any = {
        event: 'CUSTOM_APP_EVENTS',
        application_tracking_enabled: '1',
        advertiser_tracking_enabled: '1',
        advertiser_id: advertiserId,
      }
      params[`${accessTokenRes.data.access_token}`] = accessTokenRes.data.access_token
      params['custom_events'] = [
        {
          _eventName: 'fb_mobile_complete_registration',
          user_events_id: await userEventsId(),
        },
      ]

      await axiosInstance.post(`https://graph.facebook.com/${appId}/activities`, null, {
        params,
        paramsSerializer: (params: any) => qs.stringify(params),
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const fbAddToCartEvent = async (contentData: string, contentId: string, contentType: string, price: number) => {
  try {
    if (!appId || !appSecret || !advertiserId) return

    const accessTokenRes: any = await axiosInstance.get(
      `https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`,
    )

    if (accessTokenRes?.data?.access_token) {
      const currency = getCountryCurrency() || defaultCurrency()
      const params: any = {
        event: 'CUSTOM_APP_EVENTS',
        application_tracking_enabled: '1',
        advertiser_tracking_enabled: '1',
        advertiser_id: advertiserId,
      }
      params[`${accessTokenRes.data.access_token}`] = accessTokenRes.data.access_token
      params['custom_events'] = [
        {
          _eventName: 'fb_mobile_add_to_cart',
          _valueToSum: price,
          value: price,
          fb_currency: currency,
          fb_content: contentData,
          fb_content_type: contentType,
          fb_content_id: contentId,
          user_events_id: await userEventsId(),
        },
      ]

      await axiosInstance.post(`https://graph.facebook.com/${appId}/activities`, null, {
        params,
        paramsSerializer: (params: any) => qs.stringify(params),
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const eventBeginCheckout = async (couponCode: string, valueOfCart: number, quantityProducts: number) => {
  const currency = getCountryCurrency() || defaultCurrency()

  FirebaseAnalytics.logEvent({
    name: 'begin_checkout',
    params: {
      coupon: couponCode,
      currency,
      value: valueOfCart,
      quantity: quantityProducts,
      user_events_id: await userEventsId(),
    },
  })
}

export const fbInitiateCheckoutEvent = async (contentType: string, numItems: number, totalPrice: number) => {
  try {
    if (!appId || !appSecret || !advertiserId) return

    const accessTokenRes: any = await axiosInstance.get(
      `https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`,
    )
    if (accessTokenRes?.data?.access_token) {
      const currency = getCountryCurrency() || defaultCurrency()

      const params: any = {
        event: 'CUSTOM_APP_EVENTS',
        application_tracking_enabled: '1',
        advertiser_tracking_enabled: '1',
        advertiser_id: advertiserId,
      }
      params[`${accessTokenRes.data.access_token}`] = accessTokenRes.data.access_token
      params['custom_events'] = [
        {
          _eventName: 'fb_mobile_initiated_checkout',
          _valueToSum: totalPrice,
          value: totalPrice,
          fb_currency: currency,
          fb_content_type: contentType,
          num_items: numItems,
          user_events_id: await userEventsId(),
        },
      ]

      await axiosInstance.post(`https://graph.facebook.com/${appId}/activities`, null, {
        params,
        paramsSerializer: (params: any) => qs.stringify(params),
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const setUserId = async (id: string) => {
  FirebaseAnalytics.setUserId({ userId: id })
}

export const eventForCheckout = async (
  eventName: string,
  totalValue: any,
  groupedProducts: any,
  activeCoupons: any,
  paymentMethod: any,
  comments: any,
  currentVendor: any,
) => {
  let productsQuantity = 0
  let totalDiscount: number = 0
  const currency = getCountryCurrency() || defaultCurrency()
  const productsIds: any[] = []
  const coupons: any[] = []

  Object.values(groupedProducts).forEach((group: any) => {
    group.forEach((product: any) => {
      const { quantity, providerId, _id } = product
      if (currentVendor === providerId) productsQuantity += quantity
      productsIds.push({ value: _id, vendorId: providerId })
    })
  })

  activeCoupons.forEach((couponObj: any) => {
    coupons.push({ value: couponObj.code })
    if (couponObj.totalDiscount) totalDiscount += couponObj.totalDiscount
  })

  let paymentMethodType = ''
  if (paymentMethod === 'pre-pago') paymentMethodType = 'anticipado'
  if (paymentMethod === 'post-pago') paymentMethodType = 'contra_entrega'

  const pushNotifData = getLocalStorageObject('pushNotifData')
  let fromPushNotif = false

  if (pushNotifData?.pushOpened?.coupon) fromPushNotif = isGeneratedFromPushNotif('coupon', coupons, true)

  if (pushNotifData?.pushOpened && !fromPushNotif)
    fromPushNotif = isGeneratedFromPushNotif('product', productsIds, true)

  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      value: totalValue,
      num_items: productsQuantity,
      total_discount: totalDiscount,
      payment_method: paymentMethodType,
      comments,
      currency,
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const errorCheckoutEvent = async (eventName: any, status: any) => {
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      error_type: 'Ha ocurrido un error al hacer el pedido.',
      error_status: status,
      user_events_id: await userEventsId(),
    },
  })
}

export const productPurchasedEvent = (groupedProducts: any, orderId: any, currentVendor: any) => {
  Object.values(groupedProducts).forEach((group: any) => {
    group.forEach(async ({ providerId, name, sku, quantity, _id, infoPrice: { price, totalPrice } }: any) => {
      if (providerId === currentVendor)
        FirebaseAnalytics.logEvent({
          name: 'product_purchased',
          params: {
            order_id: orderId,
            vendor_id: providerId,
            product_name: name,
            product_id: _id,
            product_sku: sku,
            product_price: price,
            quantity,
            total_price: totalPrice,
            user_events_id: await userEventsId(),
          },
        })
    })
  })
}

export const fbPurchaseEvent = async (valueOfCart: number) => {
  try {
    if (!appId || !appSecret || !advertiserId) return

    const accessTokenRes: any = await axiosInstance.get(
      `https://graph.facebook.com/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&grant_type=client_credentials`,
    )
    if (accessTokenRes?.data?.access_token) {
      const currency = getCountryCurrency() || defaultCurrency()
      const params: any = {
        event: 'CUSTOM_APP_EVENTS',
        application_tracking_enabled: '1',
        advertiser_tracking_enabled: '1',
        advertiser_id: advertiserId,
      }
      params[`${accessTokenRes.data.access_token}`] = accessTokenRes.data.access_token
      params['custom_events'] = [
        {
          _eventName: 'fb_mobile_purchase',
          _valueToSum: valueOfCart,
          fb_currency: currency,
          value: valueOfCart,
          user_events_id: await userEventsId(),
        },
      ]

      await axiosInstance.post(`https://graph.facebook.com/${appId}/activities`, null, {
        params,
        paramsSerializer: (params: any) => qs.stringify(params),
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const requestSuccessfull = async (itemId: string, itemName: string) => {
  FirebaseAnalytics.logEvent({
    name: 'send_request',
    params: {
      item_id: itemId,
      item_name: itemName,
      user_events_id: await userEventsId(),
    },
  })
}

export const validateCoupon = async (couponName: string) => {
  FirebaseAnalytics.logEvent({
    name: 'validate_coupon',
    params: {
      coupon_name: couponName,
      user_events_id: await userEventsId(),
    },
  })
}

export const extraRequestItem = async (itemSelected: string) => {
  FirebaseAnalytics.logEvent({
    name: 'extra_request_completed',
    params: {
      item_request: itemSelected,
      user_events_id: await userEventsId(),
    },
  })
}

export const logError = async (type: string, eventName: string) => {
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      error_type: type,
      user_events_id: await userEventsId(),
    },
  })
}

export const contactForms = async (eventName: string, contactType: string, contactPlatform: string) => {
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      contact_type: contactType,
      contact_platform: contactPlatform,
      user_events_id: await userEventsId(),
    },
  })
}

export const helpQuestion = async (questionSelected: string) => {
  FirebaseAnalytics.logEvent({
    name: 'help_question',
    params: {
      question: questionSelected,
      user_events_id: await userEventsId(),
    },
  })
}

export const termSearched = async (searched: string, source: string) => {
  FirebaseAnalytics.logEvent({
    name: 'search',
    params: {
      source,
      search_term: searched,
      user_events_id: await userEventsId(),
    },
  })
}

export const moreInfoProvider = async (providerName: string) => {
  FirebaseAnalytics.logEvent({
    name: 'more_info',
    params: {
      provider_name: providerName,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewProductEvent = async (
  vendorId: any,
  vendorPosition: any,
  categoryId: any,
  categoryName: any,
  categoryTree: any,
  productId: any,
  productName: any,
  productValue: any,
  productDiscount: any = 0,
  filter?: any,
) => {
  const actualPath = window.location.pathname.split('/')
  let eventSource = findOriginPath(actualPath)
  const providers = JSON.parse(localStorage.getItem('@providers') || '')
  const providerName = providers[vendorId].name

  const subCategoriesObj: any = {}

  categoryTree.forEach((category: any, index: number) => {
    const pos = index + 1
    subCategoriesObj[`sub_category_${pos}_id`] = category.id
    subCategoriesObj[`sub_category_${pos}_name`] = category.name
  })

  const fromPushNotif = isGeneratedFromPushNotif('product', { value: productId, vendorId }, false)

  FirebaseAnalytics.logEvent({
    name: 'view_product',
    params: {
      source: eventSource,
      vendor_id: vendorId,
      vendor_name: providerName,
      vendor_position: vendorPosition || 'N/A',
      category_id: categoryId,
      category_name: categoryName,
      product_id: productId,
      product_name: productName,
      product_value: productValue,
      product_discount: productDiscount,
      ...subCategoriesObj,
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const viewSearchResultsEvent = (searchTerm: any, categoriesProviders: any, source: string) => {
  categoriesProviders.forEach((provider: any) => {
    const { _id, alias } = provider

    provider.products.forEach(async (product: any) => {
      const { _id: productId, name } = product

      FirebaseAnalytics.logEvent({
        name: 'view_search_results',
        params: {
          source,
          search_term: searchTerm,
          vendor_id: _id,
          vendor_name: alias,
          product_id: productId,
          product_name: name,
          user_events_id: await userEventsId(),
        },
      })
    })
  })
}

export const viewVendorEvent = async (
  source: any,
  vendorId: any,
  vendorName: any,
  vendorPosition: any,
  eventName?: any,
) => {
  const fromPushNotif = isGeneratedFromPushNotif('vendor', { value: vendorId }, false)

  FirebaseAnalytics.logEvent({
    name: eventName || 'view_vendor',
    params: {
      source,
      vendor_id: vendorId,
      vendor_name: vendorName,
      vendor_position: vendorPosition,
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const viewCouponsPageEvent = async () => {
  const fromPushNotif = isGeneratedFromPushNotif()

  FirebaseAnalytics.logEvent({
    name: 'view_coupons_page',
    params: {
      user_events_id: await userEventsId(),
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const applyCouponEvent = async (coupon: any) => {
  const fromPushNotif = isGeneratedFromPushNotif('coupon', { value: coupon }, false)

  FirebaseAnalytics.logEvent({
    name: 'apply_coupon',
    params: {
      user_events_id: await userEventsId(),
      coupon,
      generated_from: fromPushNotif ? 'push_notification' : '',
    },
  })
}

export const confirmShoppingCartClickEvent = async (
  userId: string,
  providerId: string,
  paymentType: string,
  providerTime: number,
) => {
  FirebaseAnalytics.logEvent({
    name: 'confirm_shopping_cart_click',
    params: {
      user_events_id: await userEventsId(),
      userId,
      providerId,
      paymentType,
      providerTime: `${providerTime} segundos`,
    },
  })
}

export const continueUnchangedClickEvent = async () => {
  FirebaseAnalytics.logEvent({
    name: 'continue_unchanged_click',
    params: {
      user_events_id: await userEventsId(),
    },
  })
}

export const updateCartClickEvent = async (addedProducts: string[]) => {
  FirebaseAnalytics.logEvent({
    name: 'update_cart_click',
    params: {
      user_events_id: await userEventsId(),
      addedProducts,
    },
  })
}

export const returnToCartClickEvent = async (userID: string) => {
  FirebaseAnalytics.logEvent({
    name: 'return_to_cart_click',
    params: {
      user_events_id: await userEventsId(),
      userId: userID,
    },
  })
}

export const typeQuestion = async (categoryQuestion: string) => {
  FirebaseAnalytics.logEvent({
    name: 'help_category',
    params: {
      help_category_name: categoryQuestion,
      user_events_id: await userEventsId(),
    },
  })
}

export const categoryType = async (typeCategory: string) => {
  FirebaseAnalytics.logEvent({
    name: 'click_category',
    params: {
      content_type: typeCategory,
      user_events_id: await userEventsId(),
    },
  })
}

export const activatedCouponEvent = async (couponName: string) => {
  FirebaseAnalytics.logEvent({
    name: 'activate_coupon',
    params: {
      coupon_name: couponName,
      user_events_id: await userEventsId(),
    },
  })
}

export const loginOrRegisterSuccessful = async (eventName: string, methodUse: string) => {
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      method: methodUse,
      user_events_id: await userEventsId(),
    },
  })
}

export const carouselItem = async (
  eventName: string,
  itemId: string,
  itemName: string,
  itemCategory: string,
  itemBrand?: string,
  itemSku?: string,
  providerName?: string,
) => {
  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {
      item_id: itemId,
      item_name: itemName,
      item_category: itemCategory,
      item_brand: itemBrand ?? '',
      item_sku: itemSku ?? '',
      vendor: providerName,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewDeliveryConfirmationEvent = async (vendorId: any) => {
  FirebaseAnalytics.logEvent({
    name: 'view_delivery_confirmation',
    params: {
      vendor_id: vendorId,
      user_events_id: await userEventsId(),
    },
  })
}

export const selectGuestComune = (comune: any) => {
  FirebaseAnalytics.logEvent({
    name: 'select_delivery_confirmation',
    params: {
      value: comune,
    },
  })
}

export const confirmDeliveryEvent = async (vendorId: any, orderValue: any, qualification: any, comment: any) => {
  FirebaseAnalytics.logEvent({
    name: 'select_delivery_confirmation',
    params: {
      vendor_id: vendorId,
      value: orderValue,
      qualification: qualification,
      comment: comment,
      user_events_id: await userEventsId(),
    },
  })
}

export const searchHomeProviderEvent = async (searchTerm: any, source: any, vendorId: any, vendorName: any) => {
  FirebaseAnalytics.logEvent({
    name: 'search',
    params: {
      search_term: searchTerm,
      source,
      vendor_id: vendorId,
      vendor_name: vendorName,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewBrandEvent = async (brandId: any, brandName: any, vendorId: any, vendorName: any) => {
  FirebaseAnalytics.logEvent({
    name: 'view_brand',
    params: {
      brand_id: brandId,
      brand_name: brandName,
      vendor_id: vendorId,
      vendor_name: vendorName,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewCatalogEvent = async (deeplink: any) => {
  FirebaseAnalytics.logEvent({
    name: 'view_catalog',
    params: {
      deeplink,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewCatalogCategoryEvent = async (deeplink: any, category: any) => {
  FirebaseAnalytics.logEvent({
    name: 'view_catalog_category',
    params: {
      deeplink,
      category,
      user_events_id: await userEventsId(),
    },
  })
}

export const selectCountryEvent = async (country: string) => {
  FirebaseAnalytics.logEvent({
    name: 'login_home_page',
    params: {
      country,
      user_events_id: await userEventsId(),
    },
  })
}

export const signUpSupportEvent = async () => {
  FirebaseAnalytics.logEvent({ name: 'sign_up_support', params: {} })
}

export const registerSuccessfulEvent = async (name: string, phone: string, email: string) => {
  const currency = getCountryCurrency() || defaultCurrency()

  FirebaseAnalytics.logEvent({
    name: 'sign_up',
    params: {
      name,
      phone,
      email,
      currency,
      user_events_id: await userEventsId(),
    },
  })
}

export const viewProviderFavoriteProducts = () => {
  FirebaseAnalytics.logEvent({ name: 'view_provider_favorite_products', params: {} })
}

export const clickCategoryProvidersFavoriteProducts = (categoryName: string) => {
  FirebaseAnalytics.logEvent({
    name: 'click_category_providers_favorite_products',
    params: {
      value: categoryName,
    },
  })
}

export const pushNotifOpen = async (deeplink: any) => {
  const currentDateTime: any = new Date()

  const pushNotifData = getLocalStorageObject('pushNotifData') || {}
  pushNotifData['pushOpened'] = {
    date: currentDateTime.toISOString(),
    ...getDeeplinkData(deeplink),
  }

  localStorage.setItem('pushNotifData', JSON.stringify(pushNotifData))

  FirebaseAnalytics.logEvent({
    name: 'push_click_open',
    params: {
      user_events_id: await userEventsId(),
    },
  })
}

export const resetPushNotif = async () => {
  localStorage.removeItem('pushNotifData')
}

const isGeneratedFromPushNotif = (eventType?: string, eventObj?: any, isArrayValidation?: boolean) => {
  const pushNotifData = getLocalStorageObject('pushNotifData')
  let isInsideTimeFrame: any = false
  let isFromNotifData: any = true

  if (pushNotifData) {
    const currentDateTime: any = new Date()
    const pushOpened = pushNotifData.pushOpened?.date || null
    const pushOpenedDate: any = pushOpened ? new Date(pushOpened) : null
    const hoursFrame = pushNotifData.pushOpened?.coupon ? 36 : 24

    isInsideTimeFrame = pushOpenedDate ? Math.abs(currentDateTime - pushOpenedDate) / 36e5 < hoursFrame : false

    if (eventType && eventObj && isArrayValidation !== undefined) {
      const typesValidations = [
        { type: 'product', validator: productValidation(pushNotifData.pushOpened, eventObj, isArrayValidation) },
        { type: 'vendor', validator: idsValidation(pushNotifData.pushOpened?.vendorId, eventObj, isArrayValidation) },
        { type: 'coupon', validator: idsValidation(pushNotifData.pushOpened?.coupon, eventObj, isArrayValidation) },
      ]

      const selectedValidation: any = typesValidations.find(({ type }: any) => type === eventType) || null
      isFromNotifData = selectedValidation.validator
    }
  }

  return isInsideTimeFrame && isFromNotifData
}

const idsValidation = (compareWith: string, eventObj: any, isArrayValidation: boolean) => {
  return isArrayValidation ? eventObj.some(({ value }: any) => value === compareWith) : compareWith === eventObj.value
}

const productValidation = (pushOpenedObj: any, eventObj: any, isArrayValidation: boolean) => {
  if (pushOpenedObj.vendorId) {
    if (isArrayValidation) return eventObj.some((productObj: any) => productObj.vendorId === pushOpenedObj.vendorId)
    return eventObj.vendorId === pushOpenedObj.vendorId
  } else {
    return idsValidation(pushOpenedObj?.vendorId, eventObj, isArrayValidation)
  }
}

const getDeeplinkData = (deeplink: any) => {
  if (!deeplink || !isValidUrl(deeplink)) return {}

  const url = new URL(deeplink)

  const urlSearchParams = url.pathname.split('/')
  urlSearchParams.shift()
  let urlSearchQuery: any = new URLSearchParams(url.search)

  const notifDataObj = Object.fromEntries(urlSearchQuery.entries())
  if (urlSearchParams[0] === 'home-providers') notifDataObj.vendorId = urlSearchParams[1]
  if (urlSearchParams[0] === 'vendor' && urlSearchParams[2] === 'product') {
    notifDataObj.vendorId = urlSearchParams[1]
    notifDataObj.productId = urlSearchParams[3]
  }

  return notifDataObj
}

const userData = () => {
  const user = getLocalUserData()

  if (!user) {
    return {}
  }
  const { username, id, name } = user
  return { userID: id, username, name }
}

const timestamp = moment().format()

export const dispatchProgramClick = async ({ cartId, vendorId, vendorName, countryCode }: any) => {
  FirebaseAnalytics.logEvent({
    name: 'dispatch_program_click',
    params: {
      user_events_id: await userEventsId(),
      ...userData(),
      vendorId,
      vendorName,
      timestamp,
      countryCode: countryCode,
      cartId,
    },
  })
}

export const dispatchModifyDayClick = async ({ cartId, vendorId, vendorName, countryCode }: any) => {
  FirebaseAnalytics.logEvent({
    name: 'dispatch_modify_day_click',
    params: {
      user_events_id: await userEventsId(),
      ...userData(),
      vendorId,
      vendorName,
      timestamp,
      countryCode: countryCode,
      cartId,
    },
  })
}

export const dispatchConfirmDayClick = async ({ cartId, vendorId, vendorName, selectedDate, countryCode }: any) => {
  FirebaseAnalytics.logEvent({
    name: 'dispatch_confirm_day_click',
    params: {
      user_events_id: await userEventsId(),
      ...userData(),
      selectedDate,
      vendorId,
      vendorName,
      timestamp,
      countryCode: countryCode,
      cartId
    }
  })
}

export const dispatchConfirmModifyDayClick = async ({
  cartId,
  vendorId,
  vendorName,
  selectedDate,
  countryCode,
  previusDate,
}: any) => {
  FirebaseAnalytics.logEvent({
    name: 'dispatch_modify_confirm_day_click',
    params: {
      user_events_id: await userEventsId(),
      ...userData(),
      selectedDate,
      vendorId,
      vendorName,
      timestamp,
      countryCode: countryCode,
      cartId,
      previusDate,
    },
  })
}

export const eventConfirmOrderClick = async ({ vendorName, providerId, shippingCostProvider }: any) => {
  FirebaseAnalytics.logEvent({
    name: 'confirm_order_click',
    params: {
      user_events_id: await userEventsId(),
      ...userData(),
      timestamp,
      countryCode: getCountryCode(),
      vendor_name: vendorName,
      vendor_id: providerId,
      shippingCostProvider,
    },
  })
}

export const eventNotificationModule = async (
  originModule: string,
  userName: string,
  timestamp: string,
  dispatchZone: string,
) => {
  console.log('notification_module')
  FirebaseAnalytics.logEvent({
    name: 'notification_module',
    params: {
      user_events_id: await userEventsId(),
      origin: originModule,
      user_name: userName,
      timestamp,
      dispatch_zone: dispatchZone,
    },
  })
}

export const eventNotificationExit = async (userName: string, timestamp: string, dispatchZone: string) => {
  FirebaseAnalytics.logEvent({
    name: 'notification_exit',
    params: {
      user_events_id: await userEventsId(),
      name: userName,
      timestamp: timestamp,
      dispatch_zone: dispatchZone,
    },
  })
}

export const eventNotificationView = async (
  notificationType: string,
  userName: string,
  timestamp: string,
  dispatchZone: string,
) => {
  FirebaseAnalytics.logEvent({
    name: 'notification_view',
    params: {
      user_events_id: await userEventsId(),
      notification_type: notificationType,
      user_name: userName,
      timestamp,
      dispatch_zone: dispatchZone,
    },
  })
}

export const eventNotificationCta = async (
  notificationType: string,
  userName: string,
  timestamp: string,
  dispatchZone: string,
  ctaContent: string,
) => {
  FirebaseAnalytics.logEvent({
    name: 'notification_cta',
    params: {
      user_events_id: await userEventsId(),
      notification_type: notificationType,
      user_name: userName,
      timestamp,
      dispatch_zone: dispatchZone,
      cta_content: ctaContent,
    },
  })
}

export const eventNotificationDelete = async (
  notificationType: string,
  userName: string,
  timestamp: string,
  dispatchZone: string,
) => {
  FirebaseAnalytics.logEvent({
    name: 'notification_delete',
    params: {
      user_events_id: await userEventsId(),
      notification_type: notificationType,
      user_name: userName,
      timestamp,
      dispatch_zone: dispatchZone,
    },
  })
}

export const eventNotificationBack = async (
  userName: string,
  timestamp: string,
  dispatchZone: string,
  notificationType: string,
) => {
  FirebaseAnalytics.logEvent({
    name: 'notification_back',
    params: {
      user_events_id: await userEventsId(),
      user_name: userName,
      timestamp,
      dispatch_zone: dispatchZone,
      notification_type: notificationType,
    },
  })
}
