import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'
import { IonContent, IonPage, IonBadge  } from '@ionic/react'
import { ProviderModel } from '../../models/ProviderModel'

import ToolBar from '../../components/tool-bar/ToolBar'

import { viewCatalogEvent, viewVendorEvent } from '../../pages/firebase/firebaseTags'
import GlobalComponents from '../../components'
import cartToolbarIcon from '../../assets/icons/nav_cart_blue.svg'
import Category from '../../models/Category'
import Office from '../../models/Office'
import Settings from '../../models/Settings'
import User from '../../models/User'
import { CartModel } from '../../models/CartModel'
import { ProductModel } from '../../models/ProductModel'
import { PromotionModel } from '../../models/PromotionModel'
import { getCartLength } from '../../utils/cart'
import { EventEmitter } from '../../utils/events'
import { ifExistAndMayorToZero } from '../../utils/arrays'
import WordsSlider from '../../components/words-slider/WordsSlider'
import { getProducts } from '../../clients/category'
import LoadingComponent from '../../components/loading-component/LoadingComponent'
import { getLocalStorageObject } from '../../utils/localstorageData'

import './AllCategorybyProvider.scss'
import { navigateToCart } from '../../utils/navigation'


interface IPathParams {}
interface RouteState {
  nameCategory: string
  providers:any
  fromDeeplink:any
  _id: any
}

type IProps = RouteComponentProps<IPathParams, StaticContext, RouteState> & {
  history:any
  user: User
  settings: Settings
  offices: Office
  categories: Category
  promotion: PromotionModel
  productModel: ProductModel
  cartModel: CartModel
  providerModel: ProviderModel
  
}

const AllCategorybyProvider: React.FC<IProps> = props => {
  const { history, location: { state: locationState } } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [availableCategories] = useState<any>([])
  const [words, setWords] = useState<any>([])
  const [cartLengthData, setCartLengthData] = useState<any>({ cartLength: 0, prevLength: 0})
  const [animateAtc, setAnimateAtc] = useState<string>('')
  const [allProviders] = useState<any>(locationState.providers.providers)
  const [allProducts, setAllProducts] = useState<any>([])

  useEffect(() => {
    registerViewCatalog()
    getProductsByVendor()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    const cart = getLocalStorageObject('@cart')

    if (cart) {
      const updatedCartLength: any = cart?.products?.length || null
      setCartLengthData({ cartLength: updatedCartLength, prevLength: updatedCartLength })
    }

    getCart()

    EventEmitter.subscribe('UpdateCartCount', ({ cartUpdateAction }: any) => {
      setCartLengthData((prevState: any) => {
        let newCartLength = prevState.cartLength
        if(cartUpdateAction === 'add') newCartLength = newCartLength + 1
        if(cartUpdateAction === 'subtract') newCartLength = newCartLength - 1
        
        const didAdd = newCartLength > prevState.cartLength
        if (didAdd) {
          setAnimateAtc('badge-anim-in')
          setTimeout(() => { setAnimateAtc('badge-anim-out') }, 1);
        }
        return { 
          cartLength: newCartLength,
          prevLength: prevState.cartLength 
        }
      })
    })

    return () => {
      EventEmitter.unsubscribe('UpdateCartCount')
    }
  }, [])

  const getCart = async () => {
    const cartLength: any = await getCartLength()
    setCartLengthData({ cartLength, prevLength: cartLength })
  }

  useEffect(() => {
    getCategoriesWithOffers()
  }, [availableCategories]) // eslint-disable-line react-hooks/exhaustive-deps

  const registerViewCatalog = () => {
    const deeplink = window.location.href
    viewCatalogEvent(deeplink)
  }

  const getProductsByVendor = async () => {
    const {providers} = locationState 
    let productsById: any= []

    await new Promise((resolve:any) => { 
      allProviders.forEach(async (provider: any, index:number) => {
        const products = await getProducts(1, provider.id, '', providers._id)
        
        productsById[index] = ({products: products.docs, name: provider.name, id: provider.id})

        if(index === allProviders.length -1) { 
          isLoadingProducts(false)
          resolve()
        }
      });
    })

    setAllProducts(productsById)
  }

  const getCategoriesWithOffers = async () => setWords(modifyPropertiesOfCategories(locationState.providers.providers))
  
  const modifyPropertiesOfCategories = (categories:any) => {
    categories.sort()
    let newArrayOfCategories = categories.map((category:any) =>{
      return {
        _id: category.id,
        name: category.name,
      };
   });

   return newArrayOfCategories
  }

  const onSeeMore = (item: any, vendorPosition: any) => {
    const { history, match , location: { state: locationState }} = props
    const idCategory:any = match.params
    const nameCategory = locationState ? locationState.nameCategory : ""

    const { id, name } = item

    viewVendorEvent('category', id, name, vendorPosition)

    history.push(`/provider/${item.id}/category/${idCategory.id}`, { 
      id: idCategory.id,
      title: nameCategory,
      singleProvider:item.id,
      categoryId:idCategory.id,
      nameProvider: item.name,
      arrow: true
    })
  }

  const showProducts = () => {

    return (
      <div className="products-slider">
        {ifExistAndMayorToZero(allProducts) &&
          allProducts.map((provider: any, index: number) => {
            provider.products.sort((a: any, b: any) => a.outOfStock === b.outOfStock ? 0 : a.outOfStock ? 1 : -1)

            return (
              <GlobalComponents.ProductSlider
                key={`category-slider-${provider.id}`}
                products={provider.products}
                info={{
                  title: provider.name,
                  id: provider.id,
                  brandId: '',
                  hide_more: false,
                  vendorName: provider.name,
                  vendorId: provider.id
                }}
                onGoToCart={goToCart}
                fromSlider={false}
                onMoreInfoClick={() => onSeeMore(provider,index)}
                {...props}
              ></GlobalComponents.ProductSlider>
            )
          })
        
          
        }
      </div>
    )
  }

  const goToCart = () => navigateToCart(history, props.location.state || history.location.state);


  const isLoadingProducts = (value:boolean) => setIsLoading(value)

    return (
      <IonPage className="offers-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='relative-header-wrapper'>
          {cartLengthData.cartLength > 0 && <IonBadge className={`cart-badge-btn ${animateAtc}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{cartLengthData.cartLength}</IonBadge> }
          <ToolBar
            title={locationState.nameCategory}
            secondaryButtons={[{ type: 'back', onClick:() => history.goBack() }]}
            secondaryHeader={true}
            boxShadow={true}
            primaryButtons={[
              {
                icon: cartToolbarIcon,
                key: 'cart',
                onClick: goToCart,
                badge: cartLengthData.cartLength,
                animateAtc,
              },
            ]}
          />
        </div>

        {ifExistAndMayorToZero(words) && <WordsSlider words={words}/> }

        <IonContent placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {isLoading && <LoadingComponent/>}
          {!isLoading && ifExistAndMayorToZero(allProducts) && showProducts()}
          <div className="container-height"></div>
        </IonContent>
      </IonPage>
    )
}

export default (withRouter(AllCategorybyProvider))
