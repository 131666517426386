import React, { useContext } from 'react'
import './index.scss'
import { IonIcon } from '@ionic/react'
import { arrowBackOutline, cartOutline, searchOutline } from 'ionicons/icons'
import AppContext from '../../context/AppContext'
import { useHistory } from 'react-router-dom'

function NavbarHomeProviders() {
  const { platform } = useContext(AppContext)
  const history = useHistory()

  return (
    <header className={`home-provider-header ${platform === 'ios' ? 'mobile' : 'web'}`}>
      <div>
        <IonIcon icon={arrowBackOutline} onClick={() => history.push('/home')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
        <h3>Gurú Despacho</h3>
      </div>

      <div>
        <IonIcon icon={searchOutline} size="large" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
        <IonIcon icon={cartOutline} size="large" onClick={() => history.push('/cart2')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
      </div>
    </header>
  )
}

export default NavbarHomeProviders
