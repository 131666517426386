import React, { useEffect, useState  } from 'react'
import { IonIcon, IonButton, IonLabel, IonPage, IonContent } from '@ionic/react'

//COMPONENTS
import TextInput from '../../components/input-form/TextInput'
import SearchInput from '../../components/search-input/SearchInput'
import PhotosUser from './../photosUser/PhotosUser'

//ICONS
import backSmallArrow from '../../assets/icons/nav_left.svg'
import GuruIcon from 'guru-react-icons'

import GoogleMap from '../google-map/GoogleMap'
import { 
  getComuneById, 
  getCoordinates, 
  getCoordinatesOfCurrentOffice, 
  getOfficesByUserId, 
  createRequests,
  uploadImage,
  deleteImage,
} from '../../clients/offices'
import { getBusinessTypes, getComunesByDepartmentAndCountryCode, getComunesByProvincesAndCountryCode, getDepartmentsByCountryCode, getProvincesByDepartmentsAndCountryCode } from '../../clients/register'
import TitleWithText from '../title-with-text/TitleWithText'
import AlertModal from '../../components/modals/AlertModal'
import './Office.scss'

interface OfficeProps {
  office?: any
  closeOfficeUser?: any
  officeExtraData: any
  user: any
}

type SelectOptionProperties = {
  id: string;
  label: string;
  value: string;
}

const OfficeUser: React.FC<OfficeProps> = props => {
  const { closeOfficeUser, office, officeExtraData, user } = props
  const [coordinates, setCoordinates] = useState<any>({})
  const [officeData, setOfficeData] = useState<any>({})
  const [country, setCountry] = useState<any>({})
  const [businessTypes, setBusinessTypes] = useState<any>([])
  const [departments, setDepartments] = useState<any>([])
  const [comunes, setComunes] = useState<any>([])
  //* Campo Perú
  const [provinces, setProvinces] = useState<SelectOptionProperties[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<SelectOptionProperties | ''>('')
  //*-----------
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>('')
  const [selectedDepartment, setSelectedDepartment] = useState<SelectOptionProperties | ''>('')
  const [selectedComune, setSelectedComune] = useState<SelectOptionProperties | ''>('')
  const [openModifyOffice, setOpenModifyOffice] = useState<boolean>(false)
  const [isRequestedChanges, setIsRequestedChanges] = useState<boolean>(false)
  const [address, setAddress] = useState<any>('')
  const [photos, setPhotos] = useState<any>('')
  const [userCurrent, setUserCurrent] = useState<any>('')
  const [alertFromTooltip, setAlertFromTooltip] = useState<boolean>(false)
  const [sendRequestsModal, setSendRequestsModal] = useState<boolean>(false)
  const [textModalSendRequests, setTextModalSendRequests] = useState<any>('')

  useEffect(() => {
    getOfficesUser()
    getDepartmentsByCountry()
  }, [])


  useEffect(() => {
    if (address && selectedDepartment) getMap(address, selectedDepartment)
  }, [address, selectedDepartment])

  const getOfficesUser = async () => {
    let result:any = {}
    const countryData = localStorage.getItem('countryName')
     
    if (countryData) {
      const parsedCountry = JSON.parse(countryData)
      setCountry(parsedCountry)
      if(!office) { 
        result = await getCoordinates('Argomedo 350', 'Santiago', 'Chile') || {}
      } else {
        result = await getCoordinatesOfCurrentOffice(office._id) || {}
        const provinceOfficeData: SelectOptionProperties = {id: office?.comuneId?.province, label: office?.comuneId?.province, value: office?.comuneId?.province}
        const deparmentOfficeData: SelectOptionProperties = {id: office?.comuneId?.department, label: office?.comuneId?.department, value: office?.comuneId?.department}
        const comuneOfficeData: SelectOptionProperties = {id: office?.location?.city, label:office?.location?.city, value: office?.comuneId._id}
        setOfficeData(office)
        setAddress(office.location.address)
        getProvincesByDepartmentsAndCountry(deparmentOfficeData)
        setSelectedDepartment(deparmentOfficeData);
        if (parsedCountry.countryCode === 'PE') {
          setSelectedProvince(provinceOfficeData);
          getComunesByProvincesAndCountry(provinceOfficeData)
        } else {
          getComunes(deparmentOfficeData)
        }
        setSelectedComune(comuneOfficeData)
      }
    }

    setCoordinates(result)
    setUserCurrent(user)
  }

  const getBusinessType = async () => { 
    const allBusinessTypes = await getBusinessTypes(country.countryCode)
  
    let newPropertiesOfBusinessTypes: any[] = allBusinessTypes?.map((businessType:any) => {
      return { value: businessType.name, label: businessType.name, id: businessType._id }
    })
    setBusinessTypes(newPropertiesOfBusinessTypes)
    
    if(newPropertiesOfBusinessTypes?.length) {
      const matchBusinessType = newPropertiesOfBusinessTypes.find(({ id }:any)=> id === office.bussinesTypeId)
      setSelectedBusinessType(matchBusinessType.id)
    }
  }

  const getDepartmentsByCountry = async () => {
    const countryData = localStorage.getItem('countryName')

    if (countryData) {
      let newPropertiesOfDeparments: any = []
      const parsedCountry = JSON.parse(countryData)
      const fetchDeparments = await getDepartmentsByCountryCode(parsedCountry.countryCode)
      setCountry(parsedCountry)

      newPropertiesOfDeparments = fetchDeparments?.map((deparment:any) => {
        return { value: deparment, label: deparment, id: deparment }
      })

      setDepartments(newPropertiesOfDeparments)
    }
  }

  const getProvincesByDepartmentsAndCountry = async (department: SelectOptionProperties) => {
    const countryData = localStorage.getItem('countryName')
   
    if (countryData) {
      let newPropertiesOfProvinces: SelectOptionProperties[] = []
      const parsedCountry = JSON.parse(countryData)
      const fetchProvinces = await getProvincesByDepartmentsAndCountryCode(department.value, parsedCountry.countryCode)

      newPropertiesOfProvinces = fetchProvinces ? fetchProvinces?.map((province: any) => {
        return { value: province, label: province, id: province }
      }) : []

      setProvinces(newPropertiesOfProvinces)
    }
  }

  const getComunesByProvincesAndCountry = async (province:any) => {
    const countryData = localStorage.getItem('countryName')
  
    if (countryData) {
      let newPropertiesOfComunes: any = []
      const parsedCountry = JSON.parse(countryData)
      const fetchComunes = await getComunesByProvincesAndCountryCode(province.value, parsedCountry.countryCode)

      newPropertiesOfComunes = fetchComunes?.map((comune:any) => {
      return { value: comune._id, label: comune.name, id: comune.name }
    })
           
      setComunes(newPropertiesOfComunes)
    }
  }

  const getComunes = async (department:any) => {
    const countryData = localStorage.getItem('countryName')
  
    if (countryData) {
      let newPropertiesOfComunes: any = []
      const parsedCountry = JSON.parse(countryData)
      const fetchComunes = await getComunesByDepartmentAndCountryCode(department.value, parsedCountry.countryCode)
      setCountry(parsedCountry)

      newPropertiesOfComunes = fetchComunes?.map((comune:any) => {
      return { value: comune._id, label: comune.name, id: comune.name }
    })
           
      setComunes(newPropertiesOfComunes)
    }
  }

  const findBusinessTypeById = (businessTypeId: string) => { 
    return businessTypes?.find(({id}: any) => id ===  businessTypeId)?.value
  }


  const onSelectOptionDepartment = (value: SelectOptionProperties) => {
    setSelectedDepartment(value)
    if (country.countryCode === 'PE') {
      setProvinces([])
      setComunes([])
      getProvincesByDepartmentsAndCountry(value)
    } else {
      setComunes([])
      getComunes(value)
    }
  }

  const onSelectOptionProvince = (value: SelectOptionProperties) => {
    setSelectedProvince(value)
    setComunes([])
    getComunesByProvincesAndCountry(value)
  }

  const onAcceptRequestChanges = () => { 
    setIsRequestedChanges(true)
    setOpenModifyOffice(false)
  }

  const onChangeDirection = async (event:any) => {
    setAddress(event.target.value)
  }

  const getMap = async (adress:any, departments:any ) => {
    const mapAddress = await getCoordinates(adress, departments.value, 'Chile')
    setCoordinates(mapAddress)
  }

  const disabledSendButton = () => {
    let disabled = true
    const checkInputsForm = address.trim()  !== '' && 
      selectedDepartment !== '' && 
      selectedComune

    if(checkInputsForm) disabled = false
    if(Object.keys(office).length > 0  && (address !== '' ||  selectedDepartment !== '' || selectedComune  !== '' )) disabled = false
 
    return disabled
  }

  const disabledUpdateButton = () => {
    let disabled = true

    const checkInputsForm =  address?.trim() !== '' &&
      (address !== Object.keys(officeData).length > 0 && officeData?.location?.address ||
      (selectedComune !== '' && selectedComune.id !== officeData?.location?.city))
    
      if (checkInputsForm) disabled = false

      return disabled
  }

  const sendRequests = async () => {
    let data = {}

    if(officeExtraData.action === "editOffice") {
      data = {
        ... returnData("update"),
        "bussinesTypeId": office.bussinesTypeId, 
        "officeId": officeData._id
      }
    } else {
      data = {
        ... returnData("create"),
        "images": photos,
      }
    }

    try {
      const result:any =  await createRequests(data)
      Object.keys(result).length > 0 &&  setSendRequestsModal(true)
      if(Object.keys(office).length > 0) {
        setTextModalSendRequests("Te llamaremos pronto para confirmar. Hasta entonces, la dirección seguirá igual.")
      } else {
        setTextModalSendRequests("Te llamaremos pronto para confirmar tu nueva sucursal.")
      }
  
    } catch (error) {
        console.error("Error", error)
    }
  }

  const returnData = (action: string) => {
    const data = {
      "address" : address || officeData?.location?.address , 
      "comuneId": selectedComune ? selectedComune.value : officeData.comuneId, 
      "userId":userCurrent.id, 
      "action": action,
      "location" : {
        "latitude": coordinates.latitude,
        "longitude": coordinates.longitude
      },
    }
    return data
  }

  const photoUser = async (arrPhoto: any, image:any) => {
    const arr = arrPhoto.map((item:any) => `data:image/${image.format};base64,${item}` )
    setPhotos(arr)
    
    if(officeExtraData.action === "editOffice" && image){
      const img = {"image":`data:image/${image.format};base64,${image.base64String}`}
       const result:any = await uploadImage(img, office._id)

       if(Object.keys(result).length > 0){
          const currentOffice = {...officeData, "images": result.images}
          setOfficeData(currentOffice)
       }
    }
  }

  const deleteImagenSelected  = async (photoSelected:any) => {
    if(Object.keys(officeData).length > 0) {
      const result:any =  await deleteImage(officeData._id, photoSelected.photo)
      const currentOffice = {...officeData, "images": result.office.images}
      setOfficeData(currentOffice)
    }

    officeExtraData.action === 'addOffice' && setPhotos((prevPhotos: any[]) => {
      return prevPhotos.filter((photo: any, index: number) => index !== photoSelected?.index)
      })
  }

  const openAlertFromTooltip = (value:boolean) => setAlertFromTooltip(value)

  const onRequestChanges = () => setOpenModifyOffice(true)

  const onDidDismissAlert = () => setOpenModifyOffice(false)

  const showForm = () => officeExtraData.action !== "editOffice" || isRequestedChanges;

  const getCountryCode = (): string => {
    const countryData = localStorage.getItem('countryName')
    if (countryData) {
      const parsedCountry = JSON.parse(countryData)
      return parsedCountry.countryCode;
    }
    return ''
  }

    return (
      <IonPage className="container-office-user ds-content-page" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
         <IonContent className="container-office" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="container-office-user ds-content-page">
            <div className="container-header">
              <div className='container-title-action'>
                <div className="back-small-arrow" >
                  <IonButton onClick={() => closeOfficeUser()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon src={backSmallArrow} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </IonButton>
                </div>
                <div className="container-title">
                  <h1> Cuenta</h1>
                </div>
              </div>
              <div>
                {!showForm() && (officeExtraData.action === "editOffice" && !isRequestedChanges) &&
                  <IonButton onClick={() => onRequestChanges()} className="send" mode='ios' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Editar
                  </IonButton>
                }
              </div>
            </div>
            {Object.keys(coordinates).length > 0 &&  
              <div className="container-google-map">
                <GoogleMap coordinates={{lat: coordinates.latitude, lng: coordinates.longitude}} zoom={18} />
              </div>
            }
            {officeExtraData.action === "editOffice" && office && !isRequestedChanges && 
              <div className="info-adress">
                {office?.businessName ? <TitleWithText title="Nombre comercial" text={office?.businessName} /> : ''}
                <TitleWithText title="Dirección" text={office.location.address} />
                <TitleWithText title={getCountryCode() === 'PE' ? 'Distrito' : "Comuna"} text={office.location.city} />
                {getCountryCode() === 'PE' && <TitleWithText title='Provincia' text={office?.comuneId?.province} />}
                <TitleWithText title={getCountryCode() === 'PE' ? 'Departamento' : "Región"} text={office?.comuneId?.department} />
                { businessTypes?.length > 0 && <TitleWithText title="Tipo de negocio" text={findBusinessTypeById(office.bussinesTypeId)} />}
              </div>
            }
            <AlertModal 
              label={'¡Espera!'}
              text={'Los cambios pueden afectar el acceso a proveedores. ¿Quieres continuar?'}
              buttonText={'Sí'}
              onDismiss={onDidDismissAlert}
              isOpen={openModifyOffice}
              buttonAction={onAcceptRequestChanges}
            />
            {showForm() &&
              <div className="container-office-input">
                <div className='container-text-input'>
                  <TextInput 
                    iconValid="icn_address"
                    iconDefault="icn_address"
                    onChange={onChangeDirection}
                    placeholder="Ej: Mariano Angulo 123"
                    label="Dirección"  
                    isValid={!!address.length}
                    error={"La dirección debe tener al menos un carácter"}
                    value={address}
                    type="text"
                    toValidate={address}
                    autoComplete="off"
                  />
                </div>
                <div className="containet-select-input">
                  <IonLabel className="title-type-bussiness" {...({} as any)}>
                    {country.countryCode === 'PE' ? 'Departamento' : 'Región'}
                  </IonLabel>
                  <SearchInput 
                    onChange={(valueSelected: any) => onSelectOptionDepartment(valueSelected)} 
                    placeholder={country.countryCode === 'PE' ? 'Elige el departamento' : `Elige la región`}
                    isValid={true}
                    value={selectedDepartment}
                    type="text"
                    autoComplete="off"
                    options={departments}
                    isSearchable={true}
                    arrow={true}
                  />
                </div>
                {country.countryCode === 'PE' && 
                  <div className="containet-select-input">
                    <IonLabel className="title-type-bussiness" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Provincia</IonLabel>
                    <SearchInput 
                      onChange={(valueSelected: any) => onSelectOptionProvince(valueSelected)} 
                      placeholder='Elige la provincia'
                      isValid={true}
                      value={selectedProvince}
                      type="text"
                      autoComplete="off"
                      options={provinces}
                      arrow={true}
                      isDisabled={false}
                    />
                  </div>
                }
                <div className="containet-select-input">
                  <IonLabel className="title-type-bussiness" {...({} as any)} >
                    {country.countryCode === 'PE' ? 'Distrito' : 'Comuna'}
                  </IonLabel>
                  <SearchInput 
                    onChange={(valueSelected: any) => setSelectedComune(valueSelected)} 
                    placeholder={country.countryCode === 'PE' ? 'Elige el distrito' : `Elige la comuna`}
                    isValid={true}
                    value={selectedComune}
                    type="text"
                    autoComplete="off"
                    options={comunes}
                    arrow={true}
                    isDisabled={false}
                  />
                </div>
              </div>
            }
            <div className="container-patent">
              <div className="container-patent-tittle">
                <h5>Documentos</h5>
                <div className="icon" onClick={() => openAlertFromTooltip(true)}><GuruIcon nameIcon="act_info"/></div>
              </div>
              <PhotosUser 
                arrPhoto={photoUser}
                officeData={officeData}
                deleteImagenSelected={deleteImagenSelected}
              />
            </div>
            {showForm() &&
              <div className='container-btn-submit'>
                {officeExtraData.action === "editOffice" && isRequestedChanges ?
                  <IonButton onClick={() => sendRequests()} className="btn-submit" disabled={disabledUpdateButton()} mode='ios' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Solicitar cambio
                  </IonButton> :
                  <IonButton onClick={() => sendRequests()} className="btn-submit" disabled={disabledSendButton()} mode='ios' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Enviar
                  </IonButton>
                }
              </div>
            }
            {alertFromTooltip && 
              <AlertModal 
                label={'Ten en cuenta que...'}
                text={'Los documentos deben ser en formato jpg, jpeg y png. No aceptamos archivos en pdf.'}
                buttonText={'Ok'}
                onDismiss={() => openAlertFromTooltip(false)}
                isOpen={alertFromTooltip}
                buttonAction={() => openAlertFromTooltip(false)}
              />}
            {sendRequestsModal && 
              <AlertModal 
                label={'Solicitud enviada'}
                text={textModalSendRequests}
                buttonText={'Volver a mi cuenta'}
                onDismiss={() => {
                  setSendRequestsModal(false)
                  closeOfficeUser()
                }}
                isOpen={sendRequestsModal}
                buttonAction={() => closeOfficeUser()}
              />}
            <style>
              {`
                .footer-menu-home {
                display: none !important; 
                }
              `}
            </style>
          </div> 
        </IonContent>
    </IonPage >
    )
}

export default OfficeUser