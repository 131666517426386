import { IonIcon, IonModal } from '@ionic/react'
import React, { useContext } from 'react'
import './index.scss'
import { arrowBackOutline } from 'ionicons/icons'
import GuruHelper from '../../components/guru-helper/GuruHelper'
import AppContext from '../../context/AppContext'
import ProviderSection from './components/provider_section'
import { useHistory, useParams } from 'react-router-dom'
import useGetSuggestedData from '../../hooks/suggested/useGetSuggestedData'
import LoadingComponent from '../../components/loading-component/LoadingComponent'

interface Params {
  paymentMethod: string
}

function Suggested() {
  const { countryInfo } = useContext(AppContext)
  const history = useHistory()
  const { paymentMethod } = useParams<Params>()
  const { suggestedData, loading, openModal } = useGetSuggestedData(paymentMethod)

  return (
    <IonModal isOpen={openModal} id="suggested-modal">
      <div className="wrapper">
        <div>
          <div className="header">
            <IonIcon icon={arrowBackOutline} onClick={() => history.push('/cart2')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>
            <span>
              {countryInfo.countryCode === 'CL' && '¡Te pillamos!'}
              {countryInfo.countryCode === 'PE' && '¡Ampay!'}
              {countryInfo.countryCode === 'MX' && 'Llévele, llévele'}
            </span>
            <span></span>
          </div>

          <div className="guru-helper">
            <GuruHelper
              label=""
              text={
                countryInfo.countryCode === 'CL'
                  ? 'No olvides agregar los productos más comprados en tu comuna.'
                  : countryInfo.countryCode === 'PE'
                  ? 'No olvides agregar los productos más comprados en tu distrito.'
                  : countryInfo.countryCode === 'MX'
                  ? 'No olvides agregar los productos más comprados en tu colonia.'
                  : ''
              }
            />
          </div>

          {loading ? (
            <LoadingComponent height="60vh" />
          ) : (
            <div className="providers-content">
              {suggestedData.map((data) => (
                <ProviderSection key={data.providerId} data={data} paymentMethod={paymentMethod} />
              ))}
            </div>
          )}
        </div>

        <div className="button">
          <button onClick={() => history.push('/checkout')}>Continuar</button>
        </div>
      </div>
    </IonModal>
  )
}

export default Suggested
