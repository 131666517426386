import React, { useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import track from 'react-tracking'

// Components
import { IonContent, IonPage } from '@ionic/react'
import HeaderWithoutToolbar from '../../components/header-without-toolbar/HeaderWithoutToolbar'
import MapLocationPicker from '../../components/map-location-picker/MapLocationPicker'
import LocationSearchInput from '../../components/location-search-input/LocationSearchInput'
import { LocationConfirmationModal } from './components/LocationConfirmationModal'
import ButtonComponent from '../../components/basic-button/ButtonComponent'

// Models
import User from '../../models/User'
import Office from '../../models/Office'
import { MapLocationCoordinates } from '../../models/MapLocationCoordinates'
import { LocationInfo } from '../../models/LocationInfo'

// Assets
import arrowBackIcon from './../../assets/icons/nav_left.svg'

// Clients
import { getAddressByCoordinates } from '../../clients/offices'

// Utils
import { isFunction } from '../../utils/variables'
import { countryCl, countryMx, countryPe } from '../../utils/countriesTexts'

// Hooks
import { useUserCountryCode } from '../../hooks/useUserCountryCode'

// Styles
import './OfficeGeolocalization.scss'
import LoadingLayer from '../../components/loading/LoadingLayer'
import { getLocalStorageObject } from '../../utils/localstorageData'
import AlertModal from '../../components/modals/AlertModal'

type IPathParams = {}

type IProps = RouteComponentProps<IPathParams, StaticContext> & {
  user: User
  offices: Office
  history: any
}

const initCoordinates = (countryCode: string): MapLocationCoordinates => {
  const defaultCoordinates = { lat: -33.4499798, lng: -70.63649529999999 }
  if (!countryCode) return defaultCoordinates

  const countriesInitialCoordinates = new Map<string, MapLocationCoordinates>([
    [countryCl, { lat: -33.4499798, lng: -70.63649529999999 }],
    [countryPe, { lat: -12.046309403159286, lng: -77.04273781991358 }],
    [countryMx, { lat: 19.44339875966158, lng: -99.13274818675974 }],
  ])

  return countriesInitialCoordinates.get(countryCode) || defaultCoordinates
}

const OfficeGeolocalization: React.FC<IProps> = ({ user: userModel, offices: officeModel, history }) => {
  const userCountryCode = useUserCountryCode()
  const [addressInput, setAddressInput] = useState<string | null>(
    userCountryCode === 'CL'
      ? 'Raulí, Santiago, Región Metropolitana'
      : userCountryCode === 'PE'
      ? 'Plaza Dos de Mayo, Lima 15082, Perú'
      : userCountryCode === 'MX'
      ? 'López Rayón 18, Peralvillo, Morelos, Cuauhtémoc, 06200 Ciudad de México, CDMX, México'
      : 'Raulí, Santiago, Región Metropolitana',
  )
  const [coordinates, setCoordinates] = useState<MapLocationCoordinates>(initCoordinates(userCountryCode))
  const [locationInfo, setLocationInfo] = useState<LocationInfo>({} as LocationInfo)
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false)
  const [geolocationDisabled, setGeolocationDisabled] = useState<boolean>(false)
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      findGeolocationSuccess,
      () => {
        setGeolocationDisabled(true)
      },
      { enableHighAccuracy: true },
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFindMe = async () => {  
    // const coordinates = await Geolocation.getCurrentPosition();
    // console.log(coordinates)

    if (navigator?.geolocation) {
      !geolocationDisabled && setLoadingGeolocation(true)
      navigator.geolocation.getCurrentPosition(findGeolocationSuccess, findGeolocationError, {
        enableHighAccuracy: true,
      })
    }
  }

  const findGeolocationSuccess: PositionCallback = async (position: any) => {
    try {
      const {
        coords: { latitude, longitude },
      } = position
      const newCoors: MapLocationCoordinates = { lat: latitude, lng: longitude }

      const address = await getAddressByCoordinates(newCoors)
      if (address) processGoogleLocationData(address)
      setCoordinates(newCoors)
    } catch (error) {
      console.error(error)
    }
    setLoadingGeolocation(false)
  }

  const findGeolocationError: PositionErrorCallback = (positionError: any) => {
    setLoadingGeolocation(false)
  }

  const processGoogleLocationData = (locationData: any) => {
    setAddressInput(null)
    const {
      geometry: {
        location: { lat, lng },
      },
      address_components: addressSections,
      formatted_address: formatedAddress,
    } = locationData

    const address = getShortAddress(addressSections)
    const postalCode = getPostalCode(addressSections)
    const commune = getCommuneName(addressSections)
    const province = getProvinceName(addressSections)
    const region = getRegionName(addressSections)

    const latitude = isFunction(lat) ? lat() : lat
    const longitude = isFunction(lng) ? lng() : lng

    setAddressInput(formatedAddress)
    setLocationInfo({
      latitude,
      longitude,
      address: address,
      postalCode,
      zones: {
        ...(commune && { commune }),
        ...(province && { province }),
        ...(region && { region }),
      },
    })
    setCoordinates({ lat: latitude, lng: longitude })
  }

  const getShortAddress = (addressSections: any[]) => {
    const street = getLocationSectionByType(addressSections, 'route')?.long_name
    const streetNumber = getLocationSectionByType(addressSections, 'street_number')?.long_name
    if (!street || !streetNumber) return ''
    return `${street} ${streetNumber}`
  }

  const getCommuneName = (addressSections: any[]) => {
    const sectionNameByCountry: any = {
      [countryCl]: 'administrative_area_level_3',
      [countryPe]: 'locality',
      [countryMx]: 'sublocality_level_1',
    }

    const commune = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode])
    return commune?.long_name || commune?.short_name || ''
  }

  const getProvinceName = (addressSections: any[]) => {
    const sectionNameByCountry: any = {
      [countryCl]: '',
      [countryPe]: 'administrative_area_level_2',
      [countryMx]: 'administrative_area_level_2',
    }

    const province = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode])
    return province?.long_name || province?.short_name || ''
  }

  const getRegionName = (addressSections: any[]) => {
    const sectionNameByCountry: any = {
      [countryCl]: 'administrative_area_level_1',
      [countryPe]: 'administrative_area_level_1',
      [countryMx]: 'administrative_area_level_1',
    }

    const region = getLocationSectionByType(addressSections, sectionNameByCountry[userCountryCode])
    return region?.long_name || region?.short_name || ''
  }

  const getPostalCode = (addressSections: any[]) =>
    getLocationSectionByType(addressSections, 'postal_code')?.long_name || ''

  const getLocationSectionByType = (addressSections: any[], type: string) => {
    return addressSections.find(
      (addressSection) => addressSection.types?.length && addressSection.types?.includes(type),
    )
  }

  const openConfirmation = () => {
    if (!locationInfo?.address && addressInput) setLocationInfo({ ...locationInfo, address: addressInput })
    setWarningModalOpen(false)
    setConfirmationOpen(true)
  }

  const handleGoBack = () => {
    if (!getLocalStorageObject('@user')) return history.push('/')

    userModel.signOut()
    history.push('/')
    window.location.reload()
  }

  return (
    <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent className="office-geolocalization" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <header>
          <HeaderWithoutToolbar
            icon={arrowBackIcon}
            iconSize={'20px'}
            text="Dirección de despacho"
            onClick={handleGoBack}
          />

          {addressInput && (
            <div className="office-geolocalization__location-search">
              <LocationSearchInput
                addressInput={addressInput}
                setAddressInput={setAddressInput}
                setCoordinates={setCoordinates}
                findGeolocationSuccess={findGeolocationSuccess}
              />
            </div>
          )}
        </header>

        <main>
          <div>
            <MapLocationPicker
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              handleFindMe={handleFindMe}
              isFindMeDisabled={false}
            />
          </div>

          <div className="office-geolocalization__submit-btn-container">
            <ButtonComponent
              text="Continuar"
              className="btn-primary"
              disabled={addressInput === ''}
              onClick={() => (geolocationDisabled ? setWarningModalOpen(true) : openConfirmation())}
            />
          </div>
        </main>
      </IonContent>

      <LocationConfirmationModal
        isOpen={confirmationOpen}
        locationInfo={locationInfo}
        onGoBack={() => setConfirmationOpen(false)}
        userModel={userModel}
        officeModel={officeModel}
        history={history}
      />

      <AlertModal
        isOpen={warningModalOpen}
        label="¡Importante!"
        text={'¿Puedes confirmar que la dirección coincide con la marca en el mapa?'}
        buttonText={'Confirmar'}
        buttonAction={openConfirmation}
        onDismiss={() => setWarningModalOpen(false)}
        secondaryButtonText="Cambiar"
        secondaryButtonAction={() => setWarningModalOpen(false)}
        buttonMargin={'15px'}
      />

      {loadingGeolocation && <LoadingLayer />}

      <style>
        {`
          .footer-menu-home {
          display: none !important; 
          }
        `}
      </style>
    </IonPage>
  )
}

export default track({ page: 'OfficeGeolocalization' })(withRouter(OfficeGeolocalization))
