import React from 'react'
import './PromotionCard.scss'
import GlobalComponents from './../../components'
import { IonSlide, IonModal, IonIcon, IonTitle } from '@ionic/react'
import { close } from 'ionicons/icons'
import track from 'react-tracking'

//Models
import { Promotion } from '../../models/PromotionModel'
import { Product, ProductModel } from '../../models/ProductModel'
import { CartModel } from '../../models/CartModel'
import { totalPrice } from '../../clients/product'
import { sendMetricWithSource, trackBannersActions } from '../../pages/firebase/firebaseTags'
import { counterEvent }  from '../../clients/eventCounter'
import { manageBannerClick } from '../../utils/deeplinks'


interface IProps {
  promotion: Promotion
  productModel: ProductModel
  cartModel: CartModel
  tracking?: any
  history: any
  providers: any
  index: number
  typeBanners: string
  validateDeeplinks?: () => void
}

interface IState {
  isOpen: boolean
  showToast: boolean
  product: Product | 'None yet'
  html: any
  codeHtml: any
  user: any
  providerName: string
}

class PromotionCard extends React.PureComponent<IProps, IState> {
  state: IState = {
    isOpen: false,
    showToast: false,
    product: 'None yet',
    html: null,
    codeHtml: null,
    user: '',
    providerName: '',
  }

  async componentDidMount() {
    const {
      productModel,
      promotion: { providerId, sku, price, _id },
    } = this.props

    if (_id === 'none') return undefined

    // TODO: Revisar este caso
    if(!providerId) {
      return 
    }

    productModel.getByProviderAndSku(providerId, sku).then(product => {
      if (product) {
        this.setState({ product: { ...product, discount: product.price - price } })
      }
    })

    let providerName = ''
    if(providerId) providerName = this.getProviderName(providerId)
    
    const user = localStorage.getItem('@user') 
    const userId = user ? JSON.parse(user) :  null 
    this.setState({ user:userId.id, providerName })
  }

  onPromotionClick = async () => {
    const { promotion, history, typeBanners, validateDeeplinks } = this.props
    const { user } = this.state

    const bannerAction = 'banner_click'
    const promotionType:any = promotion
    const { _id, providerId, name} = promotion


    let providerName = ''
    if(providerId) providerName = this.getProviderName(providerId)

      console.log({providerId, providerName})

    
    await trackBannersActions(bannerAction, typeBanners, providerName, _id, 'home', name)
    await counterEvent('panel-principal', promotionType.type, user, promotionType._id, 'click')

    const promotionWithNecessaryData = {
      ...promotion,
      providerName: providerName ?? undefined,
      providerId: providerId ?? undefined,
      singleProviderId: providerId, // TODO: Don't know what does it means for loading home provider
      oneVendor: undefined, // TODO: Don't know what does it means
      minumun: undefined // TODO: Don't know what does it means
    }

    manageBannerClick(promotionWithNecessaryData, history, validateDeeplinks)
  }

  registerDeeplinkEvent = (deeplink: any) => {
    const deeplinkRoutes = deeplink.split('/')    
    if (deeplinkRoutes.includes('home-providers')) sendMetricWithSource('view_vendor', 'banners')
    if (deeplinkRoutes.includes('provider') && deeplinkRoutes.includes('category')) sendMetricWithSource('view_categories', 'banners')
    if (deeplinkRoutes.includes('vendor') && deeplinkRoutes.includes('product')) sendMetricWithSource('view_product', 'banners')
  }

  getProviderName = (providerId:any) => {
    let providerName = ''
    let providersLocal = localStorage.getItem('@providers') || ''
    if(providersLocal) {
      let providers = JSON.parse(providersLocal)
      const providersValues: any = Object.values(providers)
      providerName = providersValues.find(({id}: any) => id === providerId)?.name || ''
    }
    return providerName
  }

  private acceptModal = async (product: Product, quantity: number) => {
    const { sku, providerId, units, packageType } = product
    this.props.cartModel.setProduct({
      sku,
      providerId,
      price: (await totalPrice(product.id, quantity)).price,
      quantity,
      units,
      packageType,
      infoPrice: await totalPrice(product.id, quantity),
    })
    this.setShowToast(true)
      this.setState({
        isOpen: false,
      })
  }

  dismissModal = () => {
    setTimeout(() => {
      this.setState({
        isOpen: false,
      })
    }, 1000)
  }

  setShowToast(showToast: boolean) {
    this.setState({ showToast })
  }

  private renderCodeHtml() {
    const { html, codeHtml } = this.state
    
    return (
      <IonModal cssClass="code-html-modal-dialog" backdropDismiss={false} isOpen={html}>
        <div className="content">
          <div className="header">
            <IonIcon icon={close} onClick={() => this.setState({ html: false, codeHtml: '' })} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
          <div className="body">
            <div dangerouslySetInnerHTML={{ __html: codeHtml }} />
          </div>
        </div>
      </IonModal>
    )
  }

  private renderModal(product: Product) {
    const { isOpen } = this.state
    return (
      <GlobalComponents.ProductModal
        isOpen={isOpen}
        isNewProduct
        onAccept={this.acceptModal}
        onDismiss={this.dismissModal}
        product={product}
      />
    )
  }

  private promotionImage = ({ image }: Promotion) => {
    
    if (image && image.local) {

      return `/${image.local}/${image.url}`
    }
    const { url } = image || { url: '' }
    return `${process.env.REACT_APP_BFF_IMAGE}${url}`
  }

  render() {
    const { promotion, index, history, typeBanners } = this.props
    const { product, html, providerName } = this.state
    const { tag } = promotion

    return (
      <IonSlide 
        key={promotion._id}
        data-banner-type={typeBanners}
        data-provider-name={providerName}
        data-provider-id={promotion._id}
        data-banner-name={promotion.name}
        className={`slide-${index} banner-slide-home banner-type-${`${typeBanners}`.toLowerCase()}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >
        {product === 'None yet' ? undefined : this.renderModal(product)}
        {html && this.renderCodeHtml()}
        <img src={this.promotionImage(promotion)} onClick={this.onPromotionClick} alt="" className="promotion-img-home" style={{ borderRadius: '5px' }} />
      </IonSlide>
    )
  }
}

export default track({})(PromotionCard)
