import React, { useContext, useEffect } from 'react'
import AppContext from '../context/AppContext'

function useGetCountryInfo() {
  const { handlerCountryInfo } = useContext(AppContext)

  useEffect(() => {
    if (!localStorage.getItem('countryName')) handlerCountryInfo({ city: '', countryCode: '', countryName: '' })

    const countryInfo = JSON.parse(localStorage.getItem('countryName')!)

    handlerCountryInfo({
      city: countryInfo['city'],
      countryCode: countryInfo['country_code'],
      countryName: countryInfo['countryName'],
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <div>useGetCountryName</div>
}

export default useGetCountryInfo
