import { Device } from '@capacitor/device'
import { AppLauncher } from '@capacitor/app-launcher'

// deeplinks
import { getDeepLinkRouteData } from '../pages/deeplinks/deeplinksRoutes'
import { getProviderById } from '../clients/provider'

// Models
import { ProductModel } from '../models/ProductModel'
import { ProviderModel } from '../models/ProviderModel'
import Category from '../models/Category'

const providerModel = new ProviderModel()
const productModel = new ProductModel()
const categoryModel = new Category()

const goToCategoryIdScreen = async (categoryId: string, history: any) => {
  const categoriesList = await categoryModel.get(500, 0)
  const categoryMatch = categoriesList.find((category: any) => category._id === categoryId)

  if (categoryMatch) {
    const providers = await categoryModel.findCategoriesAllProviders(categoryId)
    if (Object.keys(providers).length) {
      const params = {
        nameCategory: categoryMatch.name,
        providers: providers,
        fromDeeplink: true,
      }
      history.push(`/category/${categoryId}`, { ...params })
      return
    }
  }
  history.push('/categories')
}

export const manageBannerClick = async (promotionBanner: any, history: any, validateDeeplinks?: any) => {
  const { deeplink, coupons, provider, providerId } = promotionBanner

  console.log("manageBannerClick",{promotionBanner})

  if (deeplink) {
    // Extraer la ruta relativa del deeplink
    const deeplinkSplitted = deeplink.split('/')
    deeplinkSplitted.shift()
    deeplinkSplitted.shift()
    deeplinkSplitted.shift()
    const relativePath = deeplinkSplitted.join('/')

    // Separar la ruta en partes
    const pathParts = relativePath.split('/')

    const route = pathParts[0]

    // Manejar rutas específicas
    if (route === 'category') {
      await goToCategoryIdScreen(pathParts[1], history)
    } else if(route === 'home-providers') {
      if(!providerId){
        return history.push(`/home`)
      }
      const provider = await getProviderById(providerId)
      if(!provider){
        return history.push(`/home`) 
      }

      const {name, minimun} = provider  
      
      const homeProvidersInputProps = {
        minumun: minimun,
        nameProvider: name,
        oneVendor: undefined, // TODO: For what is this value and where it does come from?
        singleProviderId: providerId
      }

      history.push(`/${relativePath}`, homeProvidersInputProps)
      return
      
      
    } else {
      console.log({relativePath})
      // Para otras rutas, navegar directamente
      history.push(`/${relativePath}`)
      return
    }

    // Si estamos en la página de inicio y tenemos una función de validación, la llamamos
    if (window.location.pathname === '/home' && validateDeeplinks) {
      validateDeeplinks()
    }

    return
  }

  if (coupons) {
    history.push('/coupons')
  } else if (provider) {
    let providers = providerModel.state.providers
    const provider = Object.values(providers).find((provider: any) => provider.id === providerId)
    if (provider) {
      history.push(`/home-providers/${providerId}`, {
        singleProviderId: provider.id,
        nameProvider: provider.alias,
        minumun: provider.minimun,
        banners: provider.banners,
      })
    } else {
      console.error('Provider not found:', providerId)
    }
  }
}
