import React from 'react'
import { SuggestedData } from '../../../../interfaces/suggested'
import ProductCard from '../product_card'
import './index.scss'

interface Props {
  data: SuggestedData
  paymentMethod: string
}

function ProviderSection({ data, paymentMethod }: Props) {
  return (
    <div className="suggested-providers-card">
      <h3>{data.providerName}</h3>

      <div className="products">
        {data.products.map((product) => (
          <ProductCard
            key={product.productId}
            product={product}
            providerId={data.providerId}
            paymentMethod={paymentMethod}
          />
        ))}
      </div>
    </div>
  )
}

export default ProviderSection
