import { Container } from 'unstated-typescript'
import { getFromCache } from '../utils/cache'
import { fetchProducts, fetchProductByProviderIdAndSku, fetchProductById, fetchFavorites } from '../clients/product'
import { mapProductToModel } from './Category'

export type UnitOfMeasureType = 'unit' | 'kg'

export type PackageType = 'box' | 'pack' | 'piece' | 'bag' | 'sbox' | 'xbox'

type Category = string

export interface DiscountStep {
  quantity: number
  discount: number
}

export interface Product {
  id: any
  sku: string
  brand: string
  providerId: string
  name: string
  filename?: string
  category: Category
  price: number
  packageType: PackageType
  discount?: number | DiscountStep[]
  units: number
  uom: UnitOfMeasureType
  showPrice:any
  vendorActiveInComune:boolean
  infoPrice:any
  _id: any
  paid?:boolean
  quantityMin: number
  quantityMax: number
  ladder?: any
  providerName?: string
  categoryId: string
  categoryTree: any
  outOfStock?: boolean
  stock?: number
}

interface ProductsFetched {
  products: Product[]
  updatedAt: number
}

interface CategoryWithProducts {
  [key: string]: ProductsFetched
}

interface State {
  categoryMap: CategoryWithProducts
}

const storageName = '@products'
export class ProductModel extends Container<State> {
  state: State = {
    categoryMap: {},
  }

  constructor() {
    super()

    const fromCache = getFromCache<CategoryWithProducts>(storageName)
    if (fromCache) {
      this.setState({ categoryMap: fromCache })
    }
  }

  getFavorites = async (providerId: string): Promise<any> => await fetchFavorites(providerId)
  
  getByCategory = async (category: Category, allowCache: boolean = true): Promise<Product[]> => {
    const now = new Date().getTime()
    const { categoryMap } = this.state
    if (allowCache) {
      const inState = categoryMap[category]
      if (inState != null && now - inState.updatedAt < 5000) {
        return inState.products
      }
    }
    const products = (await fetchProducts(category)).map(mapProductToModel)
    await this.setState(({ categoryMap }) => ({
      categoryMap: { ...categoryMap, [category]: { products, updatedAt: now } },
    }))
    localStorage.setItem(storageName, JSON.stringify(this.state.categoryMap))
    return products
  }

  getByProviderAndSku = async (providerId: string, sku: string): Promise<Product | undefined> => {
    return new Promise(async (resolve, reject) => { 

      let resolveVar = undefined

      if(providerId && sku) {
        const product = await fetchProductByProviderIdAndSku(providerId, sku)
        if (product) resolveVar = product
      }
      
      if (resolveVar) {
        resolve(mapProductToModel(resolveVar))
      } else {
        resolve(undefined)
        return undefined
      }
    })
  }

  getProductById = async (productId: string): Promise<Product | undefined> => {
    if(!productId ){
      return undefined
    }
    const product = await fetchProductById(productId)
    if (!product) {
      return undefined
    }
    return mapProductToModel(product)
  }
}


const packageTypesIntl: Record<PackageType, string []> = {
  box: ['Caja', 'Cajas'],
  sbox: ['Caja', 'Cajas'],
  xbox: ['Caja', 'Cajas'],
  pack: ['Pack', 'Packs'],
  piece: ['Pieza', 'Piezas'],
  bag: ['Bolsa', 'Bolsas'],
}

const uomTypesIntl: Record<UnitOfMeasureType, string []> = {
  unit: ['Unidad', 'Unidades'],
  kg: ['kg', 'kg'],
}

export const effectiveProductPrice = (product: Product, quantity: number = product.units) => {
  const { discount, price } = product
  if (!discount) return price
  if (typeof discount === 'number') return price - discount
  if (discount.length === 0) return price
  const fullfilled = discount.findIndex(step => quantity < step.quantity)
  if (fullfilled === -1) {
    return price - discount[discount.length - 1].discount
  }
  if (fullfilled === 0) {
    return price
  }
  return price - discount[fullfilled - 1].discount
}

export const packageTypeAsText = (type: PackageType, plural: boolean = false) =>
  (packageTypesIntl[type] || ['Unidad', 'Unidades'])[plural ? 1 : 0]

export const uomTypeAsText = (type: UnitOfMeasureType, plural: boolean = false) =>
  (uomTypesIntl[type] || ['Unidad', 'Unidades'])[plural ? 1 : 0]
